import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  usePost,
  useGet,
  useMutation,
  useAuth,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Button from "@vapor/react-material/Button";
import Table from "@vapor/react-material/Table";
import TableContainer from "@vapor/react-material/TableContainer";
import TableCell from "@vapor/react-material/TableCell";
import TableBody from "@vapor/react-material/TableBody";
import TableHead from "@vapor/react-material/TableHead";
import TableRow from "@vapor/react-material/TableRow";
import Paper from "@vapor/react-material/Paper";
import Autocomplete from "@vapor/react-material/Autocomplete";
import TextField from "@vapor/react-material/TextField";
import Tooltip from "@vapor/react-material/Tooltip";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";

import VaporUploader from "@vapor/react-custom/VaporUploader";

//Icons
import {
  Check as CheckIcon,
  CloseOutlined as CancelIcon,
} from "@mui/icons-material";

//Custom styles
import { divGreen, divRed } from "../../businessLogic/styles";

//import { DataGrid, GridToolbar, itIT } from "@mui/x-data-grid"; //DataGrid
import {
  LicenseInfo,
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";

//Constants, Api and Data Models
import { getLocalStorageImportType } from "../../businessLogic/bl";
import { bsaAnagrafica, bsaFascicolo } from "../../businessLogic/constants";
import {
  userPermissionModel,
  userModel,
  fascicoloModel,
  baseTipoIdentificazioneModel,
  baseTipoDocumentoModel,
  baseProvinciaModel,
  baseComuneModel,
  importMappingModel,
  baseTipoPartecipazioneModel,
  baseTipoPrestazioneModel,
  prestazioneModel,
  baseScopoPrestazioneModel,
  baseNaturaPrestazioneModel,
  basePaeseModel,
} from "../../businessLogic/models";
import {
  query_users_byCompanyId,
  query_base_tipo_anagrafica_byKey,
  query_base_paese_byIdent,
  query_base_paese_byNome,
  query_base_genere,
  query_anagrafiche_byCf,
  query_anagrafiche_byCf_PG,
  query_base_all,
  query_importMapping_byTenant,
  query_fascicolo_byTenant_byCustomer,
  query_base_tipo_prestazione_all,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_fascicolo,
  mutation_add_anagrafica,
  mutation_add_anagrafica_ident,
  mutation_add_import_mapping,
  mutation_update_import_mapping,
  mutation_add_anagrafica_fascicolo,
  mutation_add_prestazione,
  mutation_add_prestazione_users,
  mutation_update_anagrafica,
} from "../../businessLogic/mutation";

import { isAlphaNumeric } from "../../businessLogic/bl";
//Other components
import { v4 as uuidv4, version } from "uuid";
import Papa from "papaparse";

export const AnagraficaImportDesktop = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //TSDIGITAL
  const [accessToken, setAccessToken] = useState(useAuth().token);

  //Query
  const apiUsersByCompanyId = useQuery(
    query_users_byCompanyId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseAll = useQuery(
    query_base_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiTipoAnagraficaByKey = useQuery(
    query_base_tipo_anagrafica_byKey,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPaeseByIdent = useQuery(
    query_base_paese_byIdent,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseGenere = useQuery(
    query_base_genere,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBasePaeseByNome = useQuery(
    query_base_paese_byNome,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagByCf = useQuery(
    query_anagrafiche_byCf,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagByCfPG = useQuery(
    query_anagrafiche_byCf_PG,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiMappedValuesByTenantId = useQuery(
    query_importMapping_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiFascicoliByTenantIdByCustomer = useQuery(
    query_fascicolo_byTenant_byCustomer,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoPrestazione = useQuery(
    query_base_tipo_prestazione_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddFascicolo = useMutation(mutation_add_fascicolo, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagrafica = useMutation(mutation_add_anagrafica, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAnagrafica = useMutation(mutation_update_anagrafica, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagraficaIdent = useMutation(mutation_add_anagrafica_ident, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAnagraficaFascicolo = useMutation(
    mutation_add_anagrafica_fascicolo,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddMapping = useMutation(mutation_add_import_mapping, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateMapping = useMutation(mutation_update_import_mapping, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddPrestazione = useMutation(mutation_add_prestazione, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddPrestazioneUsers = useMutation(mutation_add_prestazione_users, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });

  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api

  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaAnagrafica,
      false,
      false,
      false,
      false
    )
  ); //Contiene i permessi utente

  //DataGrid
  const [dataItems, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows

  const [dataItems2, setDataItems2] = useState([]); //Contiene i records
  const [dataColumns2, setDataColumns2] = useState([]); //Contiene le columns della DataGrid
  const [dataRows2, setDataRows2] = useState([]); //Contiene le rows della DataGrid
  const [customRowItems2, setCustomRowItems2] = useState([]);
  const [actionsRowItems2, setActionsRowItems2] = useState([]);

  //TS Digital
  const tsApiWorkspace = usePost(
    "aml:workspace-write:api://",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    }
  );

  const tsApiWsRead = usePost(
    "workspaceReadApi:///search?view=BASE&page=0&size=100",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,        
        "X-User-ID": loggedUserId,
      },
    }
  )

  const tsApiWsReadGet = useGet(
    "workspaceReadApi://",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,        
        "X-User-ID": loggedUserId,
      },
    }
  )

  const [mappedValuesList, setMappedValuesList] = useState([]);
  const [mappedProfPrestList, setMappedProfPrestList] = useState([]);
  const [mappedProfList,setMappedProfList] = useState([]);
  const [utentiList, setUtentiList] = useState([]);
  const [baseTipoIdentList, setBaseTipoIdentList] = useState([]);
  const [baseTipoDocList, setBaseTipoDocList] = useState([]);
  const [baseTipoPartList, setBaseTipoPartList] = useState([]);
  const [baseProvinceList, setBaseProvinceList] = useState([]);
  const [baseComuniList, setBaseComuniList] = useState([]);
  const [basePaeseList,setBasePaeseList] = useState([]);
  const [baseTipoPrestazioneList, setBaseTipoPrestazioneList] = useState([]);
  const [baseScopoPrestazioneList, setBaseScopoPrestazioneList] = useState([]);
  const [baseNaturaPrestazioneList, setBaseNaturaPrestazioneList] = useState(
    []
  );
  const [fascicoliCustomerList, setFascicoliCustomerList] = useState([]);

  const [importFinished, setImportFinished] = useState(false);
  const [importFinishedCountOk, setImportFinishedCountOk] = useState(0);
  const [importFinishedErrors, setImportFinishedErrors] = useState([]);

  const [selImportType, setSelImportType] = useState("");

  useEffect(() => {
    loadDatas();
  }, [isDataLoaded]);
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          /**************************************/
          //Carico i permessi relativi all'utente
          setUserPermissions(
            sharedDatas
              .getPropertyByName("userPermissions")
              .filter((x) => x.area === bsaAnagrafica)[0]
          );

          /**************************************/
          //Carico gli utenti
          const usersResponse = await apiUsersByCompanyId.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const arrUsers = [];
          usersResponse.data.data.user_tenant.map((item) =>
            arrUsers.push(
              new userModel(
                item.UserId,
                item.TenantId,
                item.user.Username,
                item.user.Email,
                item.user.Name,
                item.user.Surname,
                item.user.Name + " " + item.user.Surname,
                item.Type,
                item.user.Locale,
                item.TenantTipoId,
                item.user.IsRespAntiRic,
                item.base_tipo_tenant.Value,
                item.user.IsActive,
                item.user.IsDeleted,
                item.user.IsBanned,
                item.user.CreatedOn,
                item.user.Cf
              )
            )
          );
          setUtentiList(arrUsers);

          /**************************************/
          //Carico le tabelle di base
          const apiBaseAllResponse = await apiBaseAll.fetch({
            tenantId: tenantId,
          });
          const arrTipoIdenItems = [];
          apiBaseAllResponse.data.data.base_tipo_identificazione.map((item) =>
            arrTipoIdenItems.push(
              new baseTipoIdentificazioneModel(
                item.Id,
                item.Key,
                item.Value,
                item.Order,
                item.IsSystem
              )
            )
          );
          setBaseTipoIdentList(arrTipoIdenItems);

          const arrTipoDocItems = [];
          apiBaseAllResponse.data.data.base_tipo_documento.map((item) =>
            arrTipoDocItems.push(
              new baseTipoDocumentoModel(
                item.Id,
                item.Key,
                item.Value,
                item.Order,
                item.IsSystem,
                item.PersonType
              )
            )
          );
          setBaseTipoDocList(arrTipoDocItems);

          const arrTipoPartItems = [];
          apiBaseAllResponse.data.data.base_tipo_partecipazione.map((item) =>
            arrTipoPartItems.push(
              new baseTipoPartecipazioneModel(
                item.Id,
                item.Key,
                item.Nome,
                item.Descrizione
              )
            )
          );
          setBaseTipoPartList(arrTipoPartItems);

          const arrProvinceItems = [];
          apiBaseAllResponse.data.data.base_provincia.map((item) =>
            arrProvinceItems.push(
              new baseProvinciaModel(
                item.Id,
                item.Nome,
                item.Sigla,
                item.Rischio
              )
            )
          );
          setBaseProvinceList(arrProvinceItems);

          const arrComuniItems = [];
          apiBaseAllResponse.data.data.base_comune.map((item) =>
            arrComuniItems.push(
              new baseComuneModel(
                item.Id,
                item.Codice,
                item.Provincia,
                item.Nome,
                item.CodBelfiore
              )
            )
          );
          setBaseComuniList(arrComuniItems);

          
          const arrItemPaesi = [];          
          apiBaseAllResponse.data.data.base_paese.map((item) =>          
            arrItemPaesi.push(
              new basePaeseModel(
                item.Id,
                item.Nome,
                item.Identificativo,
                item.Rischio
              )
          ));
          setBasePaeseList(arrItemPaesi);

          const tipoPrestResponse = await apiBaseTipoPrestazione.fetch();
          const tipoPrestItems = [];
          for (
            let i = 0;
            i < tipoPrestResponse.data.data.base_tipo_prestazione.length;
            i++
          ) {
            const item = tipoPrestResponse.data.data.base_tipo_prestazione[i];
            let prefixPrest = "";
            if (item.BaseTenantTipoId === 1) {
              prefixPrest = "COMM";
            } else if (item.BaseTenantTipoId === 2) {
              prefixPrest = "CONS";
            } else if (item.BaseTenantTipoId === 3) {
              prefixPrest = "AVV";
            } else if (item.BaseTenantTipoId === 4) {
              prefixPrest = "NOT";
            }

            tipoPrestItems.push(
              new baseTipoPrestazioneModel(
                item.Id,
                prefixPrest + " - " + item.Nome,
                item.Rischio,
                item.Regole
              )
            );
          }
          setBaseTipoPrestazioneList(tipoPrestItems);

          //base_scopo_prestazione
          const scopoPresItems = [];
          apiBaseAllResponse.data.data.base_scopo_prestazione.map((item) =>
            scopoPresItems.push(
              new baseScopoPrestazioneModel(
                item.Id,
                item.Key,
                item.Value,
                item.Order,
                item.IsSystem
              )
            )
          );
          setBaseScopoPrestazioneList(scopoPresItems);

          //base_natura_prestazione
          const naturaPrestItems = [];
          apiBaseAllResponse.data.data.base_natura_prestazione.map((item) =>
            naturaPrestItems.push(
              new baseNaturaPrestazioneModel(
                item.Id,
                item.Key,
                item.Value,
                item.Order,
                item.IsSystem
              )
            )
          );
          setBaseNaturaPrestazioneList(naturaPrestItems);

          //Carico i fascicoli per effettuarne il check successivamente
          const arrFascicoli = [];
          const fascResponse = await apiFascicoliByTenantIdByCustomer.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          for (let i = 0; i < fascResponse.data.data.fascicolo.length; i++) {
            const item = fascResponse.data.data.fascicolo[i];

            if (item.anagrafica_fascicolos.length > 0) {
              arrFascicoli.push({
                fascicoloId: item.Id,
                isPg: item.anagrafica_fascicolos[0].anagrafica.IsPg,
                cf: item.anagrafica_fascicolos[0].anagrafica.Cf,
                pIva: item.anagrafica_fascicolos[0].anagrafica.PIva,
                denominazione:
                  item.anagrafica_fascicolos[0].anagrafica.Denominazione,
              });
            }
          }
          setFascicoliCustomerList(arrFascicoli);

          /**************************************/
          //Carico i valori mappati
          const arrMappedValuesItems = [];
          const apiMappedValuesByTenantIdResponse =
            await apiMappedValuesByTenantId.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
            });
          apiMappedValuesByTenantIdResponse.data.data.import_mapping.map(
            (item) =>
              arrMappedValuesItems.push(
                new importMappingModel(
                  item.Id,
                  item.TenantId,
                  item.AziendaId,
                  item.Type,
                  item.MappedValue,
                  item.Value,
                  item.CreatedOn,
                  item.ModifiedOn,
                  item.CreatedBy,
                  item.ModifiedBy
                )
              )
          );
          setMappedValuesList(arrMappedValuesItems);
          setMappedProfList(arrMappedValuesItems);

          /**************************************/
          //Carico il tipo di import selezionato
          const selectedImportTypeResponse = await getLocalStorageImportType();
          const selectedImportType = selectedImportTypeResponse;
          setSelImportType(selectedImportType);
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "loadDatas",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }

  //Import da Antiriciclaggio Desktop
  const [parsedFile, setParsedFile] = useState("");
  const [parsedData, setParsedData] = useState([]);
  const [parsedValues, setParsedValues] = useState([]);

  const [parsedArrProfList, setParsedArrProfList] = useState([]);
  const [parsedArrTipoIdentList, setParsedArrTipoIdentList] = useState([]);
  const [parsedArrTipoDocList, setParsedArrTipoDocList] = useState([]);
  const [parsedArrProvinceList, setParsedArrProvinceList] = useState([]);
  const [parsedArrComuniList, setParsedArrComuniList] = useState([]);

  const handleUploadeFile = async (file) => {
    try {            
      setBackDropOpen(true);

      setParsedFile(file[0].name);

      Papa.parse(file[0], {
        header: false,
        skipEmptyLines: true,
        delimiter: "\t",
        complete: function (results) {

          let rowsArray = [];
          let valuesArray = [];

          results.data.forEach((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          setParsedData(results.data);
          setParsedValues(valuesArray);

          const arrItems = [];
          const arrItems2 = [];

          for (let i = 0; i < valuesArray.length; i++) {
            try {
              const item = valuesArray[i];

              const cf = item[15].trimStart().trimEnd();
              const pIva = item[9].trimStart().trimEnd();
              const isPg = item[3] === "S" ? false : true;

              const include = !isPg
                ? arrItems.filter((x) => x.isPg === false && x.cf === cf)
                    .length <= 0
                : arrItems.filter((x) => x.isPg === true && x.pIva === pIva)
                    .length <= 0;

              if (include) {
                const id = item[0];
                const denominazione = item[1];
                const professione = item[2];
                const addrPaese = item[10];
                const addrComune = item[11];
                const addrProvincia = item[12];
                const addrIndirizzo = item[13];
                const addrCap = item[14];
                const nascitaData = item[16];
                const nascitaComune = item[17];
                const gender = item[19] === "1" ? "M" : "F";

                const identData = item[22];
                const identCfProf = item[21].trimStart().trimEnd();
                const identTipo = item[23];
                const identDocTipo = item[24];
                const identDocN = item[25];
                const identDocRilData = item[26];
                const identDocRilAutorita = item[27];

                const rappDenominazione = item[29];
                const rappNascitaComune = item[32];            
                const rappNascitaData = item[34];
                const rappGender = item[35] === "1" ? "M" : "F";
                const rappAddrIndirizzo = item[36];
                const rappAddrCap = item[37];
                const rappAddrPaese = item[38];
                const rappAddrComune = item[39];
                const rappAddrProvincia = item[40];
                const rappCf = item[41].trimStart().trimEnd();

                const identCfProf2 = item[43].trimStart().trimEnd();
                const identData2 = item[44];
                const identTipo2 = item[45];
                const identDocTipo2 = item[46];
                const identDocN2 = item[47];
                const identDocRilData2 = item[48];
                const identDocRilAutorita2 = item[49];

                const legame = item[50]; //00 = Legale rappresentante (principale); 07 = Mandato con rappresentanza; 08 mandato senza rappresentanza; 09 = altro

                const docDataScad = item[51];
                const docDataScadRapp = item[52];
                const isLegaleRappTitEff = item[53] === "S" ? true : false;                

                //Verifico se ci sono tutti i campi obbligatori
                let isComplete = false;
                let isNotCompleteReasons = "";
                let fascicolo = null;
                if (isPg) {                  
                  isComplete =
                    pIva !== undefined &&
                    pIva !== "" &&
                    denominazione !== undefined &&
                    denominazione !== "" &&
                    addrProvincia !== undefined &&
                    addrProvincia !== "" &&
                    addrComune !== undefined &&
                    addrComune !== "";                  

                  if (!isComplete) {
                    if (pIva === undefined || pIva === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Partita Iva non presente\n";
                    }
                    if (denominazione === undefined || denominazione === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Denominazione non presente\n";
                    }
                    if (addrProvincia === undefined || addrProvincia === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Provincia non presente\n";
                    }
                    if (addrComune === undefined || addrComune === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Comune non presente\n";
                    }
                    if (addrCap === undefined || addrCap === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "CAP non presente\n";
                    }                    
                  }

                  fascicolo =
                    fascicoliCustomerList.filter(
                      (x) => x.isPg === isPg && x.pIva === pIva
                    ).length > 0
                      ? fascicoliCustomerList.filter(
                          (x) => x.isPg === isPg && x.pIva === pIva
                        )[0]
                      : null;
                } else {
                  isComplete =
                    cf !== undefined &&
                    cf !== "" &&
                    denominazione !== undefined &&
                    denominazione !== "" &&
                    professione !== undefined &&
                    professione !== "" &&
                    nascitaComune !== undefined &&
                    nascitaComune !== "" &&
                    nascitaData !== undefined &&
                    nascitaData !== "" &&
                    addrProvincia !== undefined &&
                    addrProvincia !== "" &&
                    addrComune !== undefined &&
                    addrComune !== "";
              

                  if (!isComplete) {
                    if (cf === undefined || cf === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Codice Fiscale non presente\n";
                    }
                    if (denominazione === undefined || denominazione === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Denominazione non presente\n";
                    }
                    if (professione === undefined || professione === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Professione non presente\n";
                    }
                    if (nascitaComune === undefined || nascitaComune === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons +
                        "Comune di nascita non presente\n";
                    }
                    if (nascitaData === undefined || nascitaData === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Data di nascita non presente\n";
                    }
                    if (addrProvincia === undefined || addrProvincia === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Provincia non presente\n";
                    }
                    if (addrComune === undefined || addrComune === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Comune non presente\n";
                    }
                    if (addrCap === undefined || addrCap === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "CAP non presente\n";
                    }
                  }

                  fascicolo =
                    fascicoliCustomerList.filter(
                      (x) => x.isPg === isPg && x.cf === cf
                    ).length > 0
                      ? fascicoliCustomerList.filter(
                          (x) => x.isPg === isPg && x.cf === cf
                        )[0]
                      : null;
                }

                //Se non è presente alcun fascicolo che ha come cliente quest'anagrafica
                if (fascicolo === null) {
                  arrItems.push({
                    id: id,
                    cf: cf,
                    pIva: pIva,
                    denominazione: denominazione,
                    isPg: isPg,
                    isComplete: isComplete,
                    isNotCompleteReason: isNotCompleteReasons,
                  });
                } else {
                  if (
                    arrItems2.filter(
                      (x) => x.fascicoloId === fascicolo.fascicoloId
                    ).length <= 0
                  ) {
                    arrItems2.push(fascicolo);
                  }
                }
              }
            } catch (e) {}
          }

          //Data Grid Columns Anagrafiche
          const columns = [
            {
              field: "id",
              headerName: "Id",
              type: "number",
              minWidth: 50,
              flex: 0.1,
              hide: true,
            },
            {
              field: "cf",
              headerName: "Codice Fiscale",
              minWidth: 50,
              flex: 0.3,
            },
            { field: "pIva", headerName: "P. Iva", minWidth: 50, flex: 0.3 },
            {
              field: "denominazione",
              headerName: "Denominazione",
              minWidth: 50,
              flex: 1,
            },
            {
              field: "isPg",
              headerName: "Persona giuridica",
              renderCell: renderIsPgCell,
              minWidth: 50,
              flex: 0.3,
            },
            {
              field: "isComplete",
              headerName: "Completo",
              renderCell: renderCompleteCell,
              minWidth: 50,
              flex: 0.3,
            },
          ];
          setDataColumns(columns);
          setDataItems(arrItems);
          setDataRows(arrItems);

          setDataColumns2([
            { field: "fascicoloId", headerName: "Id", flex: 0.2 },
            { field: "cf", headerName: "Codice Fiscale", flex: 0.3 },
            {
              field: "pIva",
              headerName: "Partita Iva",
              flex: 0.3,
              editable: false,
            },
            {
              field: "denominazione",
              headerName: "Denominazione anagrafica",
              flex: 1,
              editable: false,
            },
          ]);
          setDataItems2(arrItems2);
          setDataRows2(arrItems2);

          setBackDropOpen(false);
        },
      });
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "handleUploadeFile",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  const handleUploadeFileMulti = async (file) => {
    try {      
      setBackDropOpen(true);

      setParsedFile(file[0].name);

      Papa.parse(file[0], {
        header: false,
        skipEmptyLines: true,
        delimiter: "\t",
        complete: function (results) {        
          let rowsArray = [];
          let valuesArray = [];

          results.data.forEach((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          setParsedData(results.data);
          setParsedValues(valuesArray);

          const arrItems = [];
          const arrItems2 = [];            

          for (let i = 0; i < valuesArray.length; i++) {
            try {
              const item = valuesArray[i];

              const cf = item[15].trimStart().trimEnd();
              const pIva = item[9].trimStart().trimEnd();
              const isPg = pIva !== "" && cf !== pIva && cf.length === 16 && isAlphaNumeric(cf) || pIva !== "" && cf === pIva || cf === "" ? true : false             

              const include = !isPg
                ? arrItems.filter((x) => x.isPg === false && x.cf === cf)
                    .length <= 0
                : arrItems.filter((x) => x.isPg === true && x.pIva === pIva)
                    .length <= 0;

              if (include) {
                const id = item[0];
                const denominazione = item[1];
                const professione = item[2];
                const addrPaese = item[10];
                const addrComune = item[11];
                const addrProvincia = item[12];
                const addrIndirizzo = item[13];
                const addrCap = item[14];

                //Verifico se ci sono tutti i campi obbligatori
                let isComplete = false;
                let isNotCompleteReasons = "";
                let fascicolo = null;
                if (isPg) {
                  isComplete =
                    pIva !== undefined &&
                    pIva !== "" &&
                    denominazione !== undefined &&
                    denominazione !== "" &&
                    addrProvincia !== undefined &&
                    addrProvincia !== "" &&
                    addrComune !== undefined &&
                    addrComune !== "";

                  if (!isComplete) {
                    if (pIva === undefined || pIva === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Partita Iva non presente\n";
                    }
                    if (denominazione === undefined || denominazione === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Denominazione non presente\n";
                    }
                    if (addrProvincia === undefined || addrProvincia === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Provincia non presente\n";
                    }
                    if (addrComune === undefined || addrComune === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Comune non presente\n";
                    }
                    if (addrCap === undefined || addrCap === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "CAP non presente\n";
                    }
                  }

                  fascicolo =
                    fascicoliCustomerList.filter(
                      (x) => x.isPg === isPg && x.pIva === pIva
                    ).length > 0
                      ? fascicoliCustomerList.filter(
                          (x) => x.isPg === isPg && x.pIva === pIva
                        )[0]
                      : null;
                } else {
                  isComplete =
                    cf !== undefined &&
                    cf !== "" &&
                    denominazione !== undefined &&
                    denominazione !== "" &&
                    addrProvincia !== undefined &&
                    addrProvincia !== "" &&
                    addrComune !== undefined &&
                    addrComune !== "";

                  if (!isComplete) {
                    if (cf === undefined || cf === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Codice Fiscale non presente\n";
                    }
                    if (denominazione === undefined || denominazione === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Denominazione non presente\n";
                    }
                    if (addrProvincia === undefined || addrProvincia === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Provincia non presente\n";
                    }
                    if (addrComune === undefined || addrComune === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "Comune non presente\n";
                    }
                    if (addrCap === undefined || addrCap === "") {
                      isNotCompleteReasons =
                        isNotCompleteReasons + "CAP non presente\n";
                    }
                  }

                  fascicolo =
                    fascicoliCustomerList.filter(
                      (x) => x.isPg === isPg && x.cf === cf
                    ).length > 0
                      ? fascicoliCustomerList.filter(
                          (x) => x.isPg === isPg && x.cf === cf
                        )[0]
                      : null;
                }

                //Se non è presente alcun fascicolo che ha come cliente quest'anagrafica
                if (fascicolo === null) {
                  arrItems.push({
                    id: id,
                    cf: cf,
                    pIva: pIva,
                    denominazione: denominazione,
                    isPg: isPg,
                    isComplete: isComplete,
                    isNotCompleteReason: isNotCompleteReasons,
                  });
                } else {
                  if (
                    arrItems2.filter(
                      (x) => x.fascicoloId === fascicolo.fascicoloId
                    ).length <= 0
                  ) {
                    arrItems2.push(fascicolo);
                  }
                }
              }
            } catch (e) {}
          }

          //Data Grid Columns Anagrafiche
          const columns = [
            {
              field: "id",
              headerName: "Id",
              type: "number",
              minWidth: 50,
              flex: 0.1,
              hide: true,
            },
            {
              field: "cf",
              headerName: "Codice Fiscale",
              minWidth: 50,
              flex: 0.3,
            },
            { field: "pIva", headerName: "P. Iva", minWidth: 50, flex: 0.3 },
            {
              field: "denominazione",
              headerName: "Denominazione",
              minWidth: 50,
              flex: 1,
            },
            {
              field: "isPg",
              headerName: "Persona giuridica",
              renderCell: renderIsPgCell,
              minWidth: 50,
              flex: 0.3,
            },
            {
              field: "isComplete",
              headerName: "Completo",
              renderCell: renderCompleteCell,
              minWidth: 50,
              flex: 0.3,
            },
          ];
          setDataColumns(columns);
          setDataItems(arrItems);
          setDataRows(arrItems);

          setDataColumns2([
            { field: "fascicoloId", headerName: "Id", flex: 0.2 },
            { field: "cf", headerName: "Codice Fiscale", flex: 0.3 },
            {
              field: "pIva",
              headerName: "Partita Iva",
              flex: 0.3,
              editable: false,
            },
            {
              field: "denominazione",
              headerName: "Denominazione anagrafica",
              flex: 1,
              editable: false,
            },
          ]);
          setDataItems2(arrItems2);
          setDataRows2(arrItems2);

          setBackDropOpen(false);
        },
      });
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "handleUploadeFileMulti",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };

  //DataGrid
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectedRow = async (row) => {
    try {
      setSelectedRows(row);

      //Array di supporto che contengono i valori da mappare
      let arrProf = [];
      let arrTipoIdent = [];
      let arrTipoDoc = [];
      let arrProvince = [];
      let arrComuni = [];

      for (let i = 0; i < row.length; i++) {
        const rowItem = row[i];
        const item = parsedValues.filter((x) => x[0] === rowItem)[0];

        if (selImportType === "aricdesktop") {
          const cf = item[15].trimStart().trimEnd();
          const pIva = item[9].trimStart().trimEnd();

          const related = parsedValues.filter(
            (x) =>
              (x[3] === "N" &&
                x[9].trimStart().trimEnd() !== "" &&
                x[9].trimStart().trimEnd() === pIva) ||
              (x[3] === "S" &&
                x[15].trimStart().trimEnd() !== "" &&
                x[15].trimStart().trimEnd() === cf)
          );

          for (let m = 0; m < related.length; m++) {
            const subItem = related[m];

            const addrComune = subItem[11];
            const addrProvincia = subItem[12];

            const rappAddrComune = subItem[39];
            const rappAddrProvincia = subItem[40];

            const identCfProf = subItem[21].trimStart().trimEnd();
            const identTipo = subItem[23];
            const identDocTipo = subItem[24];

            const identCfProf2 = subItem[43].trimStart().trimEnd();
            const identTipo2 = subItem[45];
            const identDocTipo2 = subItem[46];

            if (
              identTipo !== "" &&
              arrTipoIdent.filter((x) => x === identTipo).length <= 0
            ) {
              arrTipoIdent.push(identTipo);
            }
            if (
              identTipo2 !== "" &&
              arrTipoIdent.filter((x) => x === identTipo2).length <= 0
            ) {
              arrTipoIdent.push(identTipo2);
            }
            if (
              identDocTipo !== "" &&
              arrTipoDoc.filter((x) => x === identDocTipo).length <= 0
            ) {
              arrTipoDoc.push(identDocTipo);
            }
            if (
              identDocTipo2 !== "" &&
              arrTipoDoc.filter((x) => x === identDocTipo2).length <= 0
            ) {
              arrTipoDoc.push(identDocTipo2);
            }

            if (
              identCfProf !== "" &&
              arrProf.filter((x) => x === identCfProf).length <= 0
            ) {
              const utentiByCfResp = utentiList.filter(
                (x) =>
                  x.cf !== null &&
                  x.cf.toLowerCase() === identCfProf.toLowerCase()
              );
              if (arrProf.length <= 0) {
                arrProf.push(identCfProf);
              }
            }
            if (
              identCfProf2 !== "" &&
              arrProf.filter((x) => x === identCfProf2).length <= 0
            ) {
              const utentiByCfResp = utentiList.filter(
                (x) =>
                  x.cf !== null &&
                  x.cf.toLowerCase() === identCfProf2.toLowerCase()
              );
              if (arrProf.length <= 0) {
                arrProf.push(identCfProf2);
              }
            }

            if (
              addrProvincia !== "" &&
              addrProvincia !== "  " &&
              addrProvincia !== "EE" &&
              arrProvince.filter((x) => x === addrProvincia).length <= 0
            ) {
              const provinciaByIdResp = baseProvinceList.filter(
                (x) => x.sigla.toLowerCase() === addrProvincia.toLowerCase()
              );
              if (provinciaByIdResp.length <= 0) {
                arrProvince.push(addrProvincia);
              }
            }

            if (
              addrComune !== "" &&
              addrProvincia !== "EE" &&
              arrComuni.filter((x) => x === addrComune).length <= 0
            ) {
              const comuneByIdResp = baseComuniList.filter(
                (x) => x.nome.toLowerCase() === addrComune.toLowerCase()
              );
              if (comuneByIdResp.length <= 0) {
                arrComuni.push(addrComune);
              }
            }

            if (
              rappAddrProvincia !== "" &&
              rappAddrProvincia !== "  " &&
              rappAddrProvincia !== "EE" &&
              arrProvince.filter((x) => x === rappAddrProvincia).length <= 0
            ) {
              const provinciaByIdResp2 = baseProvinceList.filter(
                (x) => x.sigla.toLowerCase() === rappAddrProvincia.toLowerCase()
              );
              if (provinciaByIdResp2.length <= 0) {
                arrProvince.push(rappAddrProvincia);
              }
            }

            if (
              rappAddrComune !== "" &&
              rappAddrProvincia !== "EE" &&
              arrComuni.filter((x) => x === rappAddrComune).length <= 0
            ) {
              const comuneByIdResp2 = baseComuniList.filter(
                (x) => x.nome.toLowerCase() === rappAddrComune.toLowerCase()
              );
              if (comuneByIdResp2.length <= 0) {
                arrComuni.push(rappAddrComune);
              }
            }
          }
        } else if (selImportType === "multi") {
          const cf = item[15].trimStart().trimEnd();
          const pIva = item[9].trimStart().trimEnd();

          const related = parsedValues.filter(
            (x) =>
              (x[3] === "N" &&
                x[9].trimStart().trimEnd() !== "" &&
                x[9].trimStart().trimEnd() === pIva) ||
              (x[3] === "S" &&
                x[15].trimStart().trimEnd() !== "" &&
                x[15].trimStart().trimEnd() === cf)
          );
          
          for (let m = 0; m < related.length; m++) {
            const subItem = related[m];

            const addrComune = subItem[11];
            const addrProvincia = subItem[12];

            if (
              addrProvincia !== "" &&
              addrProvincia !== "  " &&
              addrProvincia !== "EE" &&
              arrProvince.filter((x) => x === addrProvincia).length <= 0
            ) {
              const provinciaByIdResp = baseProvinceList.filter(
                (x) => x.sigla.toLowerCase() === addrProvincia.toLowerCase()
              );
              if (provinciaByIdResp.length <= 0) {
                arrProvince.push(addrProvincia);
              }
            }

            if (
              addrComune !== "" &&
              addrProvincia !== "EE" &&
              arrComuni.filter((x) => x === addrComune).length <= 0
            ) {
              const comuneByIdResp = baseComuniList.filter(
                (x) => x.nome.toLowerCase() === addrComune.toLowerCase()
              );
              if (comuneByIdResp.length <= 0) {
                arrComuni.push(addrComune);
              }
            }            
          }

        }
      }
      
      if(row.length> 0 && arrProf.length === 0){
        arrProf.push("Professionista che identifica")
      }

      setParsedArrProfList(arrProf);
      setParsedArrTipoIdentList(arrTipoIdent);
      setParsedArrTipoDocList(arrTipoDoc);
      setParsedArrProvinceList(arrProvince);
      setParsedArrComuniList(arrComuni);
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "handleSelectedRow",
        version: sharedDatas.getPropertyByName("version"),
      });
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleChange = async (event, value, mappedValue) => {
    const { id } = event.target;

    let mappedValuesListArr = Object.assign([], mappedValuesList);

    if (id.indexOf("ddlProf-option") !== -1) {
      if (
        mappedValuesListArr.filter(
          (x) => x.type === "professionista" && x.mappedValue === mappedValue
        ).length <= 0
      ) {
        const result = await apiAddMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "professionista",
          mappedValue: mappedValue,
          value: value.userId,
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.insert_import_mapping_one;
          mappedValuesListArr.push(
            new importMappingModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.Type,
              item.MappedValue,
              item.Value,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy
            )
          );
        }
      } else {
        const result = await apiUpdateMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "professionista",
          mappedValue: mappedValue,
          value: value.userId,
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.update_import_mapping.returning[0];
          const currentItemIndex = mappedValuesListArr.findIndex(
            (x) => x.id === item.Id
          );
          let updatedItem = Object.assign(
            {},
            mappedValuesListArr[currentItemIndex]
          );
          updatedItem = new importMappingModel(
            item.Id,
            item.TenantId,
            item.AziendaId,
            item.Type,
            item.MappedValue,
            item.Value,
            item.CreatedOn,
            item.ModifiedOn,
            item.CreatedBy,
            item.ModifiedBy
          );
          const newArr = mappedValuesListArr.slice();
          newArr[currentItemIndex] = updatedItem;
          mappedValuesListArr = newArr;
        }
      }
      setMappedProfList(mappedValuesListArr);
    } else if (id.indexOf("ddlPrest-option") !== -1) {
      let mappedProfPrestListArr = Object.assign([], mappedProfPrestList);
      if (
        mappedProfPrestListArr.filter((x) => x.cf === mappedValue).length <= 0
      ) {
        mappedProfPrestListArr.push({
          cf: mappedValue,
          prestId: value.id,
        });
      } else {
        const currentItemIndex = mappedProfPrestListArr.findIndex(
          (x) => x.cf === mappedValue
        );
        let updatedItem = Object.assign(
          {},
          mappedProfPrestListArr[currentItemIndex]
        );
        updatedItem = {
          cf: mappedValue,
          prestId: value.id,
        };
        const newArr = mappedProfPrestListArr.slice();
        newArr[currentItemIndex] = updatedItem;
        mappedProfPrestListArr = newArr;
      }

      setMappedProfPrestList(mappedProfPrestListArr);
    } else if (id.indexOf("ddlProvince-option") !== -1) {
      if (
        mappedValuesListArr.filter(
          (x) => x.type === "provincia" && x.mappedValue === mappedValue
        ).length <= 0
      ) {
        const result = await apiAddMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "provincia",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.insert_import_mapping_one;
          mappedValuesListArr.push(
            new importMappingModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.Type,
              item.MappedValue,
              item.Value,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy
            )
          );
        }
      } else {
        const result = await apiUpdateMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "provincia",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.update_import_mapping.returning[0];
          const currentItemIndex = mappedValuesListArr.findIndex(
            (x) => x.id === item.Id
          );
          let updatedItem = Object.assign(
            {},
            mappedValuesListArr[currentItemIndex]
          );
          updatedItem = new importMappingModel(
            item.Id,
            item.TenantId,
            item.AziendaId,
            item.Type,
            item.MappedValue,
            item.Value,
            item.CreatedOn,
            item.ModifiedOn,
            item.CreatedBy,
            item.ModifiedBy
          );
          const newArr = mappedValuesListArr.slice();
          newArr[currentItemIndex] = updatedItem;
          mappedValuesListArr = newArr;
        }
      }
    } else if (id.indexOf("ddlComuni-option") !== -1) {
      if (
        mappedValuesListArr.filter(
          (x) => x.type === "comune" && x.mappedValue === mappedValue
        ).length <= 0
      ) {
        const result = await apiAddMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "comune",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.insert_import_mapping_one;
          mappedValuesListArr.push(
            new importMappingModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.Type,
              item.MappedValue,
              item.Value,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy
            )
          );
        }
      } else {
        const result = await apiUpdateMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "comune",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.update_import_mapping.returning[0];
          const currentItemIndex = mappedValuesListArr.findIndex(
            (x) => x.id === item.Id
          );
          let updatedItem = Object.assign(
            {},
            mappedValuesListArr[currentItemIndex]
          );
          updatedItem = new importMappingModel(
            item.Id,
            item.TenantId,
            item.AziendaId,
            item.Type,
            item.MappedValue,
            item.Value,
            item.CreatedOn,
            item.ModifiedOn,
            item.CreatedBy,
            item.ModifiedBy
          );
          const newArr = mappedValuesListArr.slice();
          newArr[currentItemIndex] = updatedItem;
          mappedValuesListArr = newArr;
        }
      }
    } else if (id.indexOf("ddlTipoIdent-option") !== -1) {
      if (
        mappedValuesListArr.filter(
          (x) => x.type === "tipoIdent" && x.mappedValue === mappedValue
        ).length <= 0
      ) {
        const result = await apiAddMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "tipoIdent",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.insert_import_mapping_one;
          mappedValuesListArr.push(
            new importMappingModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.Type,
              item.MappedValue,
              item.Value,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy
            )
          );
        }
      } else {
        const result = await apiUpdateMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "tipoIdent",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.update_import_mapping.returning[0];
          const currentItemIndex = mappedValuesListArr.findIndex(
            (x) => x.id === item.Id
          );
          let updatedItem = Object.assign(
            {},
            mappedValuesListArr[currentItemIndex]
          );
          updatedItem = new importMappingModel(
            item.Id,
            item.TenantId,
            item.AziendaId,
            item.Type,
            item.MappedValue,
            item.Value,
            item.CreatedOn,
            item.ModifiedOn,
            item.CreatedBy,
            item.ModifiedBy
          );
          const newArr = mappedValuesListArr.slice();
          newArr[currentItemIndex] = updatedItem;
          mappedValuesListArr = newArr;
        }
      }
    } else if (id.indexOf("ddlModIdentDoc-option") !== -1) {
      if (
        mappedValuesListArr.filter(
          (x) => x.type === "tipoDoc" && x.mappedValue === mappedValue
        ).length <= 0
      ) {
        const result = await apiAddMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "tipoDoc",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.insert_import_mapping_one;
          mappedValuesListArr.push(
            new importMappingModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.Type,
              item.MappedValue,
              item.Value,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy
            )
          );
        }
      } else {
        const result = await apiUpdateMapping.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          type: "tipoDoc",
          mappedValue: mappedValue,
          value: value.id.toString(),
        });
        if (result.data.errors !== undefined) {
        } else {
          const item = result.data.data.update_import_mapping.returning[0];
          const currentItemIndex = mappedValuesListArr.findIndex(
            (x) => x.id === item.Id
          );
          let updatedItem = Object.assign(
            {},
            mappedValuesListArr[currentItemIndex]
          );
          updatedItem = new importMappingModel(
            item.Id,
            item.TenantId,
            item.AziendaId,
            item.Type,
            item.MappedValue,
            item.Value,
            item.CreatedOn,
            item.ModifiedOn,
            item.CreatedBy,
            item.ModifiedBy
          );
          const newArr = mappedValuesListArr.slice();
          newArr[currentItemIndex] = updatedItem;
          mappedValuesListArr = newArr;
        }
      }
    }

    setMappedValuesList(mappedValuesListArr);
  };
  const handleCancelClick = () => {
    setDataItems([]);
    setDataColumns([]);
    setDataRows([]);
  };
  const handleNewImportClick = () => {
    setImportFinished(false);
    setImportFinishedErrors([]);
    setImportFinishedCountOk(0);
    setDataItems([]);
    setDataColumns([]);
    setDataRows([]);
    setSelectedTab("tabImportabili");
    setIsDataLoaded(false);
  };
  const renderIsPgCell = (item) => {
    return item.row.isPg ? "Sì" : "No";
  };
  const renderCompleteCell = (item) => {
    const status = item.row.isComplete;
    const statusReasons = item.row.isNotCompleteReason;
    let completeS = "No";

    switch (status) {
      case true: {
        completeS = "Sì";
        break;
      }
      case false: {
        completeS = "No";
        break;
      }
      default: {
        break;
      }
    }

    if (status) {
      return (
        <div style={{ ...(status === false ? divRed : divGreen) }}>
          {completeS}
        </div>
      );
    } else {
      return (
        <Tooltip
          title={
            <span style={{ whiteSpace: "pre-line" }}>{statusReasons}</span>
          }
          arrow
          placement="right"
        >
          <div style={{ ...(status === false ? divRed : divGreen) }}>
            {completeS}
          </div>
        </Tooltip>
      );
    }
  };
  const checkFormValues = () => {      
    if(mappedProfList.filter(x=> x.type === "professionista").length > 0){
      return true;
    }
    else{
      return false;
    }
  }

  const handleConfirmImportClick = async () => {  
    if(checkFormValues()){
      try {      
        setBackDropOpen(true);
        enqueueSnackbar(
          "L'operazione potrà richiedere qualche minuto, ti consigliamo di non chiudere il browser.",
          { variant: "warning" }
        );

        if (selectedRows.length > 0) {
          const importErrorsArr = [];

          for (let i = 0; i < selectedRows.length; i++) {
            const selectedRow = selectedRows[i];
            let denominazione = "";
            let cf = "";
            let pIva = "";
            let codLegalRapp = "";

            try {
              const item = parsedValues.filter((x) => x[0] === selectedRow)[0];

              cf = item[15].trimStart().trimEnd();
              pIva = item[9].trimStart().trimEnd();
              denominazione = item[1];
              codLegalRapp = item[28]
              
              let isPg
              if(selImportType === "aricdesktop"){
                isPg = item[3] === "S" ? false : true;
              } else {
                isPg = pIva !== "" && cf !== pIva && cf.length === 16 && isAlphaNumeric(cf) || pIva !== "" && cf === pIva || cf === "" ? true : false
              }

              if (isPg && cf === "") {
                cf = pIva;
              }

              const related = parsedValues.filter(
                (x) =>
                  (x[3] === "N" &&
                    x[9].trimStart().trimEnd() !== "" &&
                    x[9].trimStart().trimEnd() === pIva) ||
                  (x[3] === "S" &&
                    x[15].trimStart().trimEnd() !== "" &&
                    x[15].trimStart().trimEnd() === cf)
              );                                   

              //Creazione del cliente sia aricDesktop che Multi              
              let fascicoloResultModel = null;
              let clienteCreatedSuccefully = true;
              let anagByCfResponse = null;
              let hasFascicolo = false;
              const clienteItem = related[0];

              const professionisti = [];
              professionisti.push(clienteItem[21].trimStart().trimEnd());

              //Verifico l'esistenza del fascicolo                          
              if (!isPg) {                            
                 hasFascicolo = fascicoliCustomerList.filter((x) => x.isPg === isPg && x.cf === cf).length > 0;
              } else {                     
                hasFascicolo = fascicoliCustomerList.filter((x) => x.isPg === isPg && x.pIva === pIva).length > 0;
              } 

              if (selImportType === "aricdesktop") {              
                //Cliente                
                if(!hasFascicolo){
                  let cliente = await createCliente(fascicoloResultModel, clienteItem);
                  if(cliente === null || cliente === undefined){             
                    throw new Error("Non è stato possibile creare correttamente il cliente")                    
                  }              
                }
              } else if (selImportType === "multi") {
                //Cliente
                if(!hasFascicolo){
                  let clienteMulti = await createClienteMulti(fascicoloResultModel, clienteItem);
                  if(clienteMulti === null){
                    throw new Error("Non è stato possibile creare correttamente il cliente")                    
                  }
                }                    
              }

              //Verifico l'esistenza del CF come cliente
              if(!isPg){
                anagByCfResponse = await apiAnagByCf.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  cf: "%" + cf + "%",
                  nome: "%%",
                  cognome: "%%",
                });  
              } else {
                anagByCfResponse = await apiAnagByCfPG.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  cf: "%" + cf + "%",
                  pIva: "%" + pIva + "%",
                  denominazione: "%%",
                });     
              }
              
              if (hasFascicolo) {
                importErrorsArr.push({
                  denominazione: denominazione,
                  cf: cf,
                  pIva: pIva,
                  reason: "Fascicolo già presente per questa anagrafica",
                });
              } else if (clienteCreatedSuccefully) { 
                //Creo il fascicolo
                const fascicoloResponse = await createFascicolo(denominazione);

                if (fascicoloResponse !== null) {
                  fascicoloResultModel = fascicoloResponse;
                }              
              }

              if(codLegalRapp !== "" && selImportType === "aricdesktop"){                
                //Creazione del legale rappresentante
                for (let m = 0; m < related.length; m++) {
                  const legRappItem = related[m];

                  if (
                    legRappItem[43].trimStart().trimEnd() !== "" &&
                    professionisti.filter(
                      (x) => x === legRappItem[43].trimStart().trimEnd()
                    ).length <= 0
                  ) {
                    professionisti.push(legRappItem[43].trimStart().trimEnd());
                  }

                  //Legale rappresentante
                  await createLegaleRapp(fascicoloResultModel, legRappItem);
                }
               }        

                //Legale rappresentante multi                
                if (
                  selImportType === "multi" &&
                  clienteItem[19] !== "" &&
                  clienteItem[20] !== "" &&
                  clienteItem[21] !== "" &&
                  clienteItem[28] !== ""
                ) {
                  await createLegaleRappMulti(
                    fascicoloResultModel,
                    clienteItem
                  );
                }
                     
        
              //Prendo il tipo di anagrafica del cliente
              const tipoAnagKey = isPg ? "tipo_anagrafica_2" : "tipo_anagrafica_1";
              const tipoAnagByKeyResp = await apiTipoAnagraficaByKey.fetch({
                key: tipoAnagKey,
              });
              const tipoAnagId =
                tipoAnagByKeyResp.data.data.base_tipo_anagrafica.length > 0
                  ? tipoAnagByKeyResp.data.data.base_tipo_anagrafica[0].Id
                  : 0;

              //Se esiste aggiungo solo la relazione tra anagrafica e fascicolo
              if (
                anagByCfResponse !== null &&
                anagByCfResponse.data.data.anagrafica.length > 0
              ) {
                const addAnagFascicoloResponse = await apiAddAnagraficaFascicolo.fetch({
                  anagraficaId: anagByCfResponse.data.data.anagrafica[0].Id,
                  fascicoloId: fascicoloResultModel.id,
                  tipoAnagId: tipoAnagId,
                });
              }

              //Prestazione
              if(selImportType === "aricdesktop"){
                for (let p = 0; p < professionisti.length; p++) {
                  const profItem = professionisti[p];
                  const identCfProf = mappedValuesList.filter(
                    (x) =>
                      x.type === "professionista" && x.mappedValue === profItem
                  )[0].value;
  
                  let identData = clienteItem[22];
                  let dataIdentD = new Date();
                  if (identData !== "") {
                    const [day, month, year] = identData.split("/");
                    const dataIdent = new Date(+year, +month - 1, +day);
                    dataIdentD = new Date(
                      Date.UTC(
                        dataIdent.getFullYear(),
                        dataIdent.getMonth(),
                        dataIdent.getDate(),
                        1,
                        0,
                        0
                      )
                    );
                  }
  
                  const addrPaeseEstero = clienteItem[10];
                  let addrComune = clienteItem[11];
                  let addrProvincia = clienteItem[12];
  
                  if (addrProvincia !== "EE") {
                    if (
                      addrProvincia !== null &&
                      addrProvincia !== "" &&
                      parsedArrProvinceList.filter((x) => x === addrProvincia)
                        .length > 0 &&
                      mappedValuesList.filter(
                        (x) =>
                          x.type === "provincia" &&
                          x.mappedValue === addrProvincia
                      ).length > 0
                    ) {
                      addrProvincia = parseInt(
                        mappedValuesList.filter(
                          (x) =>
                            x.type === "provincia" &&
                            x.mappedValue === addrProvincia
                        )[0].value
                      );
                    } else {
                      addrProvincia =
                        addrProvincia !== ""
                          ? parseInt(
                              baseProvinceList.filter(
                                (x) =>
                                  x.sigla.toLowerCase() ===
                                  addrProvincia.toLowerCase()
                              )[0].id
                            )
                          : null;
                    }
  
                    if (
                      addrComune !== null &&
                      addrComune !== "" &&
                      parsedArrComuniList.filter((x) => x === addrComune)
                        .length > 0 &&
                      mappedValuesList.filter(
                        (x) =>
                          x.type === "comune" && x.mappedValue === addrComune
                      ).length > 0
                    ) {
                      addrComune = parseInt(
                        mappedValuesList.filter(
                          (x) =>
                            x.type === "comune" && x.mappedValue === addrComune
                        )[0].value
                      );
                    } else {
                      addrComune =
                        addrComune !== ""
                          ? parseInt(
                              baseComuniList.filter(
                                (x) =>
                                  x.nome.toLowerCase() ===
                                  addrComune.toLowerCase()
                              )[0].id
                            )
                          : null;
                    }
                  }
  
                  const paeseByIdResp = await apiPaeseByIdent.fetch({
                    identificativo: "ITA",
                  });
                  let paeseId =
                    paeseByIdResp.data.data.base_paese.length > 0
                      ? paeseByIdResp.data.data.base_paese[0].Id
                      : 0;
                  if (addrProvincia === "EE") {
                    const paeseResp = await apiBasePaeseByNome.fetch({
                      nome: addrPaeseEstero.toLowerCase(),
                    });
                    if (paeseResp.data.data.base_paese.length > 0) {
                      paeseId = paeseResp.data.data.base_paese[0].Id;
                    }
                  }
  
                  await createPrestazione(
                    fascicoloResultModel,
                    identCfProf,
                    dataIdentD,
                    paeseId,
                    addrProvincia,
                    addrComune
                  );
                }        
              }
            } catch (e) {
              importErrorsArr.push({
                denominazione: denominazione,
                cf: cf,
                pIva: pIva,
                reason: "Si è verificato un errore durante l'import",
              });
              apiAddLog.fetch({
                tenantId: sharedDatas.getPropertyByName("tenantId"),
                aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "AnagraficaImportDesktop",
                method: "handleConfirmImportClick",
                version: sharedDatas.getPropertyByName("version"),
              });
            }
          }

          setImportFinishedErrors(importErrorsArr);
          setImportFinishedCountOk(selectedRows.length - importErrorsArr.length);
          setSelectedRows([]);

          setParsedData([]);
          setParsedValues([]);
          setParsedArrProfList([]);
          setParsedArrTipoIdentList([]);
          setParsedArrTipoDocList([]);
          setParsedArrProvinceList([]);
          setParsedArrComuniList([]);
        }
      } catch (e) {
        apiAddLog.fetch({
          tenantId: sharedDatas.getPropertyByName("tenantId"),
          aziendaId: sharedDatas.getPropertyByName("aziendaId"),
          level: "ERROR",
          message: e.message,
          stack: e.stack,
          area: "AnagraficaImportDesktop",
          method: "handleConfirmImportClick",
          version: sharedDatas.getPropertyByName("version"),
        });
        enqueueSnackbar(t("error.generale"), { variant: "error" });
      } finally {
        setImportFinished(true);
        setBackDropOpen(false);
      }
    } else {
      enqueueSnackbar("Specificare un professionista per l'importazione", { variant: "error" });
    }
  };
  const createFascicolo = async (denominazione) => {
    try {
      var fascicolo = new fascicoloModel(
        0,
        tenantId,
        aziendaId,
        denominazione,
        new Date(),
        false,
        new Date(),
        null,
        [],
        "",
        "",
        loggedUserId
      );
      const fascicoloResp = await apiAddFascicolo.fetch({
        tenantId: tenantId,
        aziendaId: aziendaId,
        name: fascicolo.name,
        date: fascicolo.date,
        isPrivate: fascicolo.isPrivate,
      });
      if (fascicoloResp.data.errors !== undefined) {
        return null;
      } else {
        const result = fascicoloResp.data.data.insert_fascicolo_one;
        const fascicoloResultModel = new fascicoloModel(
          result.Id,
          result.TenantId,
          result.AziendaId,
          result.Name,
          result.Date,
          result.IsPrivate,
          result.CreatedOn,
          result.ModifiedOn,
          [],
          "",
          "",
          result.CreatedBy,
          result.ModifiedBy
        );

        //Aggiungo la Audit per tracciare l'azione
        const newValues = fascicoloResultModel;
        apiAddAudit.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          area: bsaFascicolo,
          operation: "Create",
          entityType: "fascicolo",
          entityId: result.Id.toString(),
          oldValues: "",
          newValues: JSON.stringify(newValues),
        });

        return fascicoloResultModel;
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "createFascicolo",
        version: sharedDatas.getPropertyByName("version"),
      });
      return null;
    }
  };
  const createCliente = async (fascicolo, item) => {
    try {
      let cf = item[15].trimStart().trimEnd();
      let pIva = item[9].trimStart().trimEnd();
      let denominazione = item[1];
      const professione = item[2];
      const isPg = item[3] === "S" ? false : true;

      if (isPg && cf === "") {
        cf = pIva;
      }

      const addrPaeseEstero = item[10];
      let addrComune = item[11];
      let addrProvincia = item[12];
      const addrIndirizzo = item[13];
      const addrCap = item[14];
      const nascitaData = item[16];
      const nascitaComune = item[17];
      const gender = item[19] === "1" ? "M" : "F";

      let identData = item[22];
      let identCfProf = item[21].trimStart().trimEnd();
      let identTipo = item[23];
      let identDocTipo = item[24];
      const identDocN = item[25];
      let identDocRilData = item[26];
      const identDocRilAutorita = item[27];

      const docDataScad = item[51];

      if (identData !== "" && identDocRilData === "") {
        identDocRilData = identData;
      }

      /**************************************/
      //Recupero i valori mappati
      /**************************************/
      if (addrProvincia !== "EE") {
        if (
          addrProvincia !== null &&
          addrProvincia !== "" &&
          parsedArrProvinceList.filter((x) => x === addrProvincia).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "provincia" && x.mappedValue === addrProvincia
          ).length > 0
        ) {
          addrProvincia = parseInt(
            mappedValuesList.filter(
              (x) => x.type === "provincia" && x.mappedValue === addrProvincia
            )[0].value
          );
        } else {
          addrProvincia =
            addrProvincia !== ""
              ? parseInt(
                  baseProvinceList.filter(
                    (x) => x.sigla.toLowerCase() === addrProvincia.toLowerCase()
                  )[0].id
                )
              : null;
        }

        if (
          addrComune !== null &&
          addrComune !== "" &&
          parsedArrComuniList.filter((x) => x === addrComune).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "comune" && x.mappedValue === addrComune
          ).length > 0
        ) {
          addrComune = parseInt(
            mappedValuesList.filter(
              (x) => x.type === "comune" && x.mappedValue === addrComune
            )[0].value
          );
        } else {
          addrComune =
            addrComune !== ""
              ? parseInt(
                  baseComuniList.filter(
                    (x) => x.nome.toLowerCase() === addrComune.toLowerCase()
                  )[0].id
                )
              : null;
        }
      }

      if (
        identTipo !== null &&
        identTipo !== "" &&
        parsedArrTipoIdentList.filter((x) => x === identTipo).length > 0 &&
        mappedValuesList.filter(
          (x) => x.type === "tipoIdent" && x.mappedValue === identTipo
        ).length > 0
      ) {
        identTipo = parseInt(
          mappedValuesList.filter(
            (x) => x.type === "tipoIdent" && x.mappedValue === identTipo
          )[0].value
        );
      } else {
        identTipo = parseInt(
          baseTipoIdentList.filter((x) => x.key === "tipo_identificazione_1")[0]
            .id
        );
      }

      if (
        identDocTipo !== null &&
        identDocTipo !== "" &&
        parsedArrTipoDocList.filter((x) => x === identDocTipo).length > 0 &&
        mappedValuesList.filter(
          (x) => x.type === "tipoDoc" && x.mappedValue === identDocTipo
        ).length > 0
      ) {
        identDocTipo = parseInt(
          mappedValuesList.filter(
            (x) => x.type === "tipoDoc" && x.mappedValue === identDocTipo
          )[0].value
        );
      } else {
        if (
          baseTipoIdentList.filter((x) => x.id === identTipo)[0].key ===
          "tipo_identificazione_5"
        ) {
          identDocTipo = parseInt(
            baseTipoDocList.filter((x) => x.key === "tipo_documento_8")[0].id
          );
        } else {
          identDocTipo = isPg
            ? parseInt(
                baseTipoDocList.filter((x) => x.key === "tipo_documento_3")[0]
                  .id
              )
            : parseInt(
                baseTipoDocList.filter((x) => x.key === "tipo_documento_1")[0]
                  .id
              );
        }
      }

      if (
        identCfProf !== null &&
        identCfProf !== "" &&
        parsedArrProfList.filter((x) => x === identCfProf).length > 0 &&
        mappedValuesList.filter(
          (x) => x.type === "professionista" && x.mappedValue === identCfProf
        ).length > 0
      ) {
        identCfProf = mappedValuesList.filter(
          (x) => x.type === "professionista" && x.mappedValue === identCfProf
        )[0].value;
      } else {
        identCfProf = utentiList.filter((x) => x.cf === identCfProf)[0].userId;
      }

      /**************************************/
      //Anagrafica
      /**************************************/      
      const paeseByIdResp = await apiPaeseByIdent.fetch({
        identificativo: "ITA",
      });
      let paeseId =
        paeseByIdResp.data.data.base_paese.length > 0
          ? paeseByIdResp.data.data.base_paese[0].Id
          : 0;
      if (addrProvincia === "EE") {
        const paeseResp = await apiBasePaeseByNome.fetch({
          nome: addrPaeseEstero.toLowerCase(),
        });
        if (paeseResp.data.data.base_paese.length > 0) {
          paeseId = paeseResp.data.data.base_paese[0].Id;
        }
      }

      let nome = "";
      let cognome = "";
      let genereId = 0;

      if (!isPg) {
        const baseGenereListResponse = await apiBaseGenere.fetch({
          tenantId: tenantId,
        });
        if (baseGenereListResponse.data.data.base_genere.length > 0) {
          genereId = baseGenereListResponse.data.data.base_genere.filter(
            (x) => x.Value === gender
          )[0].Id;
        }

        let spacesCount = denominazione.split(" ").length -1;
        if(spacesCount === 1){
          cognome = denominazione !== "" ? denominazione.split(" ")[0] : denominazione;
          nome = denominazione !== "" ? denominazione.split(" ")[1] : denominazione;
        } else if( spacesCount > 1){
          for(let i = 0 ; i <2;i++){            
              cognome = cognome.concat(`${denominazione.split(" ")[i]} `) 
            }
          }          
          for ( let j = 2; j <= spacesCount; j++){
            nome = nome.concat(`${denominazione.split(" ")[j]} `)
          }
          nome = nome.trimEnd();
          cognome = cognome.trimEnd();        
      }
      
      let dataNascitaD = null;
      if (nascitaData !== "") {
        const [day, month, year] = nascitaData.split("/");
        const dataNascita = new Date(+year, +month - 1, +day);
        dataNascitaD = new Date(
          Date.UTC(
            dataNascita.getFullYear(),
            dataNascita.getMonth(),
            dataNascita.getDate(),
            1,
            0,
            0
          )
        );
      }

      //Dati import
      let importDesc = "";
      importDesc =
        "Data: " +
        new Date().toString() +
        " - File: " +
        parsedFile +
        " - N. records file: " +
        parsedValues.length.toString();

      
      //Creo il record di anagrafica      
      let dataIdentD = new Date();
      if (identData !== "") {
        const [day, month, year] = identData.split("/");
        const dataIdent = new Date(+year, +month - 1, +day);
        dataIdentD = new Date(
          Date.UTC(
            dataIdent.getFullYear(),
            dataIdent.getMonth(),
            dataIdent.getDate(),
            1,
            0,
            0
          )
        );
        

        const anagraficaResp = await apiAddAnagrafica.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          parentId: 0,
          isPg: isPg,
          cf: cf === "" ? pIva : cf,
          nome: nome,
          cognome: cognome,
          nascitaLuogo: nascitaComune,
          nascitaData: dataNascitaD,
          professione: professione,
          denominazione: denominazione,
          pIva: pIva,
          reaComune: "",
          reaN: "",
          addrPaeseId: paeseId,
          addrProvinciaId: addrProvincia !== "EE" ? addrProvincia : null,
          addrProvincia: addrProvincia === "EE" ? addrProvincia : "",
          addrComuneId: addrProvincia !== "EE" ? addrComune : null,
          addrComune: addrProvincia === "EE" ? addrComune : "",
          addrIndirizzo: addrIndirizzo,
          addrN: "",
          addrCap: addrCap,
          addr2PaeseId: null,
          addr2ProvinciaId: null,
          addr2Provincia: "",
          addr2ComuneId: null,
          addr2Comune: "",
          addr2Indirizzo: "",
          addr2N: "",
          addr2Cap: "",
          isPep: false,
          isNotPepMoreThanYear: false,
          versionN: 1,
          versionDate: dataIdentD,
          isDeleted: false,
          genereId: isPg ? 0 : genereId,
          importDesc: importDesc,
          workspaceId: null,
          Office1Id: null,
          Office2Id: null,
        });

        if (anagraficaResp.data.errors !== undefined) {
          return null;
        } else {
          const result = anagraficaResp.data.data.insert_anagrafica_one;
          /**************************************/
          //Aggiungo l'identificazione
          /**************************************/
          let dataDocRilIlD = null;
          if (identDocRilData !== "") {
            const [day, month, year] = identDocRilData.split("/");
            const dataDocRilIl = new Date(+year, +month - 1, +day);
            dataDocRilIlD = new Date(
              Date.UTC(
                dataDocRilIl.getFullYear(),
                dataDocRilIl.getMonth(),
                dataDocRilIl.getDate(),
                1,
                0,
                0
              )
            );
          }

          let dataDocScadD = null;
          if (docDataScad !== "") {
            const [day, month, year] = docDataScad.split("/");
            const dataDocScad = new Date(+year, +month - 1, +day);
            dataDocScadD = new Date(
              Date.UTC(
                dataDocScad.getFullYear(),
                dataDocScad.getMonth(),
                dataDocScad.getDate(),
                1,
                0,
                0
              )
            );
          } else {
            if (identDocRilData !== "") {
              const [day, month, year] = identDocRilData.split("/");
              const dataDocScad = new Date(+year, +month - 1, +day);
              dataDocScadD = new Date(
                Date.UTC(
                  dataDocScad.getFullYear(),
                  dataDocScad.getMonth(),
                  dataDocScad.getDate(),
                  1,
                  0,
                  0
                )
              );
            }
          }

          if (
            dataIdentD !== null &&
            dataDocRilIlD !== null &&
            dataDocScadD !== null
          ) {
            const addAnagIdentResponse = await apiAddAnagraficaIdent.fetch({
              anagraficaId: result.Id,
              modalitaIdentId: identTipo,
              docTipoId: identDocTipo,
              docN: identDocN,
              docRilDa: identDocRilAutorita,
              docRilData: dataDocRilIlD,
              docScad: dataDocScadD,
              apNome: identCfProf.includes("-") ? "" : identCfProf,
              apCf: identCfProf.includes("-") ? "" : identCfProf,
              identifiedByUser: identCfProf,
              identifiedOn: dataIdentD,
            });
          }          

          /**************************************/
          //Salvo le modifiche su Workspace
          /**************************************/           
          var anagByWs = await searchAnagOnWorkSpace(cf)
          var workspaceId = ""
          var officeId = ""
          var amlId = ""
          if(anagByWs !== undefined && anagByWs.length > 0)
          {
            //Recupero gli id dal workspace
            workspaceId = anagByWs[0].id           
            officeId = await getOfficesByWsId(workspaceId)
            amlId = await getAmlByWsId(workspaceId)       
          } else {
            //Posto i nuovi dati su workspace
            var response = await savePersonalDataOnWorkspace(
              cf,
              pIva,
              denominazione,
              nome,
              cognome,
              dataNascitaD,
              nascitaComune,
              isPg,
              genereId
            );
            workspaceId = response !== undefined ? response.data.id : "";          
            officeId = await saveOfficesOnWorkSpace(
              workspaceId,
              addrComune,
              addrProvincia,
              addrIndirizzo,
              paeseId,
              addrCap
            );          
            amlId = await saveAmlDatasOnWorkSpace(
              workspaceId,
              identCfProf,
              identDocTipo,
              professione,
              false
            )                 
          }

          //Inserisco gli id nel DB
          if(workspaceId !== "" && officeId !== "" && amlId !== ""){
            await saveIdsOnDatabase(workspaceId, officeId,amlId, result, isPg);
          }
        }

        return anagraficaResp;
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "createCliente",
        version: sharedDatas.getPropertyByName("version"),
      });
      return null;
    }
  };
  const createClienteMulti = async (fascicolo, item) => {
    try {      
      let cf = item[15].trimStart().trimEnd();
      let pIva = item[9].trimStart().trimEnd();
      let denominazione = item[1];
      const professione = item[2];      
      const isPg = pIva !== "" && cf !== pIva && cf.length === 16 && isAlphaNumeric(cf) || pIva !== "" && cf === pIva || cf === "" ? true : false

      if (isPg && cf === "") {
        cf = pIva;
      }

      const addrPaeseEstero = item[10];
      let addrComune = item[11];
      let addrProvincia = item[12];
      const addrIndirizzo = item[13];
      const addrCap = item[14];
      const nascitaData = item[16];
      const nascitaComune = item[17];
      const gender = item[18] === "2" ? "F" : "M";

      /**************************************/
      //Recupero i valori mappati
      /**************************************/
      if (addrProvincia !== "EE") {
        if (
          addrProvincia !== null &&
          addrProvincia !== "" &&
          parsedArrProvinceList.filter((x) => x === addrProvincia).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "provincia" && x.mappedValue === addrProvincia
          ).length > 0
        ) {
          addrProvincia = parseInt(
            mappedValuesList.filter(
              (x) => x.type === "provincia" && x.mappedValue === addrProvincia
            )[0].value
          );
        } else {
          addrProvincia =
            addrProvincia !== ""
              ? parseInt(
                  baseProvinceList.filter(
                    (x) => x.sigla.toLowerCase() === addrProvincia.toLowerCase()
                  )[0].id
                )
              : null;
        }

        if (
          addrComune !== null &&
          addrComune !== "" &&
          parsedArrComuniList.filter((x) => x === addrComune).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "comune" && x.mappedValue === addrComune
          ).length > 0
        ) {
          addrComune = parseInt(
            mappedValuesList.filter(
              (x) => x.type === "comune" && x.mappedValue === addrComune
            )[0].value
          );
        } else {
          addrComune =
            addrComune !== ""
              ? parseInt(
                  baseComuniList.filter(
                    (x) => x.nome.toLowerCase() === addrComune.toLowerCase()
                  )[0].id
                )
              : null;
        }
      }

      /**************************************/
      //Anagrafica
      /**************************************/
      const tipoAnagKey = isPg ? "tipo_anagrafica_2" : "tipo_anagrafica_1";
      const tipoAnagByKeyResp = await apiTipoAnagraficaByKey.fetch({
        key: tipoAnagKey,
      });
      const tipoAnagId =
        tipoAnagByKeyResp.data.data.base_tipo_anagrafica.length > 0
          ? tipoAnagByKeyResp.data.data.base_tipo_anagrafica[0].Id
          : 0;

      const paeseByIdResp = await apiPaeseByIdent.fetch({
        identificativo: "ITA",
      });
      let paeseId =
        paeseByIdResp.data.data.base_paese.length > 0
          ? paeseByIdResp.data.data.base_paese[0].Id
          : 0;
      if (addrProvincia === "EE") {
        const paeseResp = await apiBasePaeseByNome.fetch({
          nome: addrPaeseEstero.toLowerCase(),
        });
        if (paeseResp.data.data.base_paese.length > 0) {
          paeseId = paeseResp.data.data.base_paese[0].Id;
        }
      }

      let nome = "";
      let cognome = "";
      let genereId = 0;

      if (!isPg) {
        const baseGenereListResponse = await apiBaseGenere.fetch({
          tenantId: tenantId,
        });
        if (baseGenereListResponse.data.data.base_genere.length > 0) {
          genereId = baseGenereListResponse.data.data.base_genere.filter(
            (x) => x.Value === gender
          )[0].Id;      
        }

        cognome =
          denominazione !== "" ? denominazione.split(" ")[0] : denominazione;
        nome =
          denominazione !== "" ? denominazione.split(" ")[1] : denominazione;
      }

      let dataNascitaD = null;
      if (nascitaData !== "") {
        const [day, month, year] = nascitaData.split("/");
        const dataNascita = new Date(+year, +month - 1, +day);
        dataNascitaD = new Date(
          Date.UTC(
            dataNascita.getFullYear(),
            dataNascita.getMonth(),
            dataNascita.getDate(),
            1,
            0,
            0
          )
        );
      }

      //Dati import
      let importDesc = "";
      importDesc =
        "Data: " +
        new Date().toString() +
        " - File: " +
        parsedFile +
        " - N. records file: " +
        parsedValues.length.toString();

      //Verifico l'esistenza del CF in qualità di Cliente
      let anagByCfResponse = null;
      if (!isPg) {
        anagByCfResponse = await apiAnagByCf.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          cf: "%" + cf + "%",
          nome: "%%",
          cognome: "%%",
        });
      } else {
        anagByCfResponse = await apiAnagByCfPG.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          cf: "%" + cf + "%",
          pIva: "%" + pIva + "%",
          denominazione: "%%",
        });
      }

      //Se esiste aggiungo solo la relazione tra anagrafica e fascicolo
      if (
        anagByCfResponse !== null &&
        anagByCfResponse.data.data.anagrafica.length > 0
      ) {
        // const addAnagFascicoloResponse = await apiAddAnagraficaFascicolo.fetch({
        //   anagraficaId: anagByCfResponse.data.data.anagrafica[0].Id,
        //   fascicoloId: fascicolo.id,
        //   tipoAnagId: tipoAnagId,
        // });
        return anagByCfResponse;
      }
      //altrimenti creo il record di anagrafica
      else {
        const anagraficaResp = await apiAddAnagrafica.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          parentId: 0,
          isPg: isPg,
          cf: cf === "" ? pIva : cf,
          nome: nome,
          cognome: cognome,
          nascitaLuogo: nascitaComune,
          nascitaData: dataNascitaD,
          professione: professione,
          denominazione: denominazione,
          pIva: pIva,
          reaComune: "",
          reaN: "",
          addrPaeseId: paeseId,
          addrProvinciaId: addrProvincia !== "EE" ? addrProvincia : null,
          addrProvincia: addrProvincia === "EE" ? addrProvincia : "",
          addrComuneId: addrProvincia !== "EE" ? addrComune : null,
          addrComune: addrProvincia === "EE" ? addrComune : "",
          addrIndirizzo: addrIndirizzo,
          addrN: "",
          addrCap: addrCap,
          addr2PaeseId: null,
          addr2ProvinciaId: null,
          addr2Provincia: "",
          addr2ComuneId: null,
          addr2Comune: "",
          addr2Indirizzo: "",
          addr2N: "",
          addr2Cap: "",
          isPep: false,
          isNotPepMoreThanYear: false,
          versionN: 1,
          versionDate: new Date(),
          isDeleted: false,
          genereId: isPg ? 0 : genereId,
          importDesc: importDesc,
          workspaceId: null,
          Office1Id: null,
          Office2Id: null,
        });
        
        if (anagraficaResp.data.errors !== undefined) {
          return null;
        } else {
          const result = anagraficaResp.data.data.insert_anagrafica_one;

          /**************************************/
          //Salvo le modifiche su Workspace
          /**************************************/
          var anagByWs = await searchAnagOnWorkSpace(cf)
          let workspaceId = ""
          let officeId = ""          

          if(anagByWs !== undefined && anagByWs.length > 0)
          {
            //Recupero gli id dal workspace
            workspaceId = anagByWs[0].id
            officeId = getOfficesByWsId(workspaceId)                     
          } else {
            //Posto i nuovi dati su workspace
            var response = await savePersonalDataOnWorkspace(
              cf,
              pIva,
              denominazione,
              nome,
              cognome,
              nascitaData,
              nascitaComune,
              isPg,
              genereId
            );
            workspaceId = response !== undefined ? response.data.id : "";          
            officeId = await saveOfficesOnWorkSpace(
              workspaceId,
              addrComune,
              addrProvincia,
              addrIndirizzo,
              paeseId,
              addrCap
            );              
          }

          //Inserisco gli id nel DB
          if(workspaceId !== "" && officeId !== ""){
            await saveIdsOnDatabase(workspaceId, officeId,"", result, isPg);
          }
        }
        return anagraficaResp;
      }  
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "createCliente",
        version: sharedDatas.getPropertyByName("version"),
      });
      return null;
    }
  };
  const createLegaleRapp = async (fascicolo, item) => {
    try {
      const isPg = item[3] === "S" ? false : true;
      const professione = item[30];
      const rappDenominazione = item[29];
      const rappNascitaComune = item[32];
      const rappNascitaData = item[34];
      const rappGender = item[35] === "1" ? "M" : "F";
      const rappAddrIndirizzo = item[36];
      const rappAddrCap = item[37];
      const rappAddrPaeseEstero = item[38];
      let rappAddrProvincia = item[40];
      let rappAddrComune = item[39];
      const rappCf = item[41].trimStart().trimEnd();

      let identCfProf2 = item[43].trimStart().trimEnd();
      let identData2 = item[44];
      let identTipo2 = item[45];
      let identDocTipo2 = item[46];
      const identDocN2 = item[47];
      let identDocRilData2 = item[48];
      const identDocRilAutorita2 = item[49];

      const legame = item[50]; //00 = Legale rappresentante (principale); 07 = Mandato con rappresentanza; 08 mandato senza rappresentanza; 09 = altro

      const docDataScadRapp = item[52];
      const isLegaleRappTitEff = item[53] === "S" ? true : false;

      if (identData2 !== "" && identDocRilData2 === "") {
        identDocRilData2 = identData2;
      }

      if (
        legame !== "" &&
        rappDenominazione !== "" &&
        rappNascitaComune !== "" &&
        rappNascitaData !== "" &&
        rappGender !== "" &&
        rappAddrIndirizzo !== "" &&
        rappAddrCap !== "" &&
        rappAddrProvincia !== "" &&
        rappAddrComune !== ""
      ) {
        /**************************************/
        //Recupero i valori mappati
        /**************************************/
        if (rappAddrProvincia !== "EE") {
          if (
            rappAddrProvincia !== null &&
            rappAddrProvincia !== "" &&
            parsedArrProvinceList.filter((x) => x === rappAddrProvincia)
              .length > 0 &&
            mappedValuesList.filter(
              (x) =>
                x.type === "provincia" && x.mappedValue === rappAddrProvincia
            ).length > 0
          ) {
            rappAddrProvincia = parseInt(
              mappedValuesList.filter(
                (x) =>
                  x.type === "provincia" && x.mappedValue === rappAddrProvincia
              )[0].value
            );
          } else {
            rappAddrProvincia =
              rappAddrProvincia !== ""
                ? parseInt(
                    baseProvinceList.filter(
                      (x) =>
                        x.sigla.toLowerCase() ===
                        rappAddrProvincia.toLowerCase()
                    )[0].id
                  )
                : null;
          }

          if (
            rappAddrComune !== null &&
            rappAddrComune !== "" &&
            parsedArrComuniList.filter((x) => x === rappAddrComune).length >
              0 &&
            mappedValuesList.filter(
              (x) => x.type === "comune" && x.mappedValue === rappAddrComune
            ).length > 0
          ) {
            rappAddrComune = parseInt(
              mappedValuesList.filter(
                (x) => x.type === "comune" && x.mappedValue === rappAddrComune
              )[0].value
            );
          } else {
            rappAddrComune =
              rappAddrComune !== ""
                ? parseInt(
                    baseComuniList.filter(
                      (x) =>
                        x.nome.toLowerCase() === rappAddrComune.toLowerCase()
                    )[0].id
                  )
                : null;
          }
        }

        if (
          identTipo2 !== null &&
          identTipo2 !== "" &&
          parsedArrTipoIdentList.filter((x) => x === identTipo2).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "tipoIdent" && x.mappedValue === identTipo2
          ).length > 0
        ) {
          identTipo2 = parseInt(
            mappedValuesList.filter(
              (x) => x.type === "tipoIdent" && x.mappedValue === identTipo2
            )[0].value
          );
        } else {
          identTipo2 = parseInt(
            baseTipoIdentList.filter(
              (x) => x.key === "tipo_identificazione_1"
            )[0].id
          );
        }

        if (
          identDocTipo2 !== null &&
          identDocTipo2 !== "" &&
          parsedArrTipoDocList.filter((x) => x === identDocTipo2).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "tipoDoc" && x.mappedValue === identDocTipo2
          ).length > 0
        ) {
          identDocTipo2 = parseInt(
            mappedValuesList.filter(
              (x) => x.type === "tipoDoc" && x.mappedValue === identDocTipo2
            )[0].value
          );
        } else {
          if (
            baseTipoIdentList.filter((x) => x.id === identTipo2)[0].key ===
            "tipo_identificazione_5"
          ) {
            identDocTipo2 = parseInt(
              baseTipoDocList.filter((x) => x.key === "tipo_documento_8")[0].id
            );
          } else {
            identDocTipo2 = isPg
              ? parseInt(
                  baseTipoDocList.filter((x) => x.key === "tipo_documento_3")[0]
                    .id
                )
              : parseInt(
                  baseTipoDocList.filter((x) => x.key === "tipo_documento_1")[0]
                    .id
                );
          }
        }

        if (
          identCfProf2 !== null &&
          identCfProf2 !== "" &&
          parsedArrProfList.filter((x) => x === identCfProf2).length > 0 &&
          mappedValuesList.filter(
            (x) => x.type === "professionista" && x.mappedValue === identCfProf2
          ).length > 0
        ) {
          identCfProf2 = mappedValuesList.filter(
            (x) => x.type === "professionista" && x.mappedValue === identCfProf2
          )[0].value;
        } else {
          identCfProf2 = utentiList.filter((x) => x.cf === identCfProf2)[0]
            .userId;
        }

        /**************************************/
        //Anagrafica
        /**************************************/
        const tipoAnagKey =
          legame === "00" ? "tipo_anagrafica_3" : "tipo_anagrafica_4";
        const tipoAnagByKeyResp = await apiTipoAnagraficaByKey.fetch({
          key: tipoAnagKey,
        });
        const tipoAnagId =
          tipoAnagByKeyResp.data.data.base_tipo_anagrafica.length > 0
            ? tipoAnagByKeyResp.data.data.base_tipo_anagrafica[0].Id
            : 0;

        const paeseByIdResp = await apiPaeseByIdent.fetch({
          identificativo: "ITA",
        });
        let paeseId =
          paeseByIdResp.data.data.base_paese.length > 0
            ? paeseByIdResp.data.data.base_paese[0].Id
            : 0;
        if (rappAddrProvincia === "EE") {
          const paeseResp = await apiBasePaeseByNome.fetch({
            nome: rappAddrPaeseEstero.toLowerCase(),
          });
          if (paeseResp.data.data.base_paese.length > 0) {
            paeseId = paeseResp.data.data.base_paese[0].Id;
          }
        }

        let nome =
          rappDenominazione !== ""
            ? rappDenominazione.split(" ")[1]
            : rappDenominazione;
        let cognome =
          rappDenominazione !== ""
            ? rappDenominazione.split(" ")[0]
            : rappDenominazione;
        let genereId = 0;
        const baseGenereListResponse = await apiBaseGenere.fetch({
          tenantId: tenantId,
        });
        if (baseGenereListResponse.data.data.base_genere.length > 0) {
          genereId = baseGenereListResponse.data.data.base_genere.filter(
            (x) => x.Value === rappGender
          )[0].Id;
        }

        let dataNascitaD = null;
        if (rappNascitaData !== "") {
          const [day, month, year] = rappNascitaData.split("/");
          const dataNascita = new Date(+year, +month - 1, +day);
          dataNascitaD = new Date(
            Date.UTC(
              dataNascita.getFullYear(),
              dataNascita.getMonth(),
              dataNascita.getDate(),
              1,
              0,
              0
            )
          );
        }

        let anagLegRapp = null;
        const anagLegRappResponse = await apiAnagByCf.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          cf: "%" + rappCf + "%",
          nome: "%%",
          cognome: "%%",
        });

        let isInFascicoloLegRapp = false;
        let isInFascicoloTitEff = false;

        if (
          anagLegRappResponse !== null &&
          anagLegRappResponse.data.data.anagrafica.length > 0
        ) {
          anagLegRapp = anagLegRappResponse.data.data.anagrafica[0];

          isInFascicoloLegRapp =
            anagLegRapp.anagrafica_fascicolos.length > 0 &&
            anagLegRapp.anagrafica_fascicolos.filter(
              (y) =>
                (y.FascicoloId === fascicolo.id &&
                  y.base_tipo_anagrafica.Key === "tipo_anagrafica_3") ||
                (y.FascicoloId === fascicolo.id &&
                  y.base_tipo_anagrafica.Key === "tipo_anagrafica_4")
            ).length > 0;
          isInFascicoloTitEff =
            anagLegRapp.anagrafica_fascicolos.length > 0 &&
            anagLegRapp.anagrafica_fascicolos.filter(
              (y) =>
                y.FascicoloId === fascicolo.id &&
                y.base_tipo_anagrafica.Key === "tipo_anagrafica_5"
            ).length > 0;

          //Se l'anagrafica non è collegata al fascicolo
          if (!isInFascicoloLegRapp) {
            //Aggiungo la relazione tra anagrafica e fascicolo
            const addAnagFascicoloResponse =
              await apiAddAnagraficaFascicolo.fetch({
                anagraficaId: anagLegRapp.Id,
                fascicoloId: fascicolo.id,
                tipoAnagId: tipoAnagId,
              });
          }
        } else {
          //Dati import
          let importDesc = "";
          importDesc =
            "Data: " +
            new Date().toString() +
            " - File: " +
            parsedFile +
            " - N. records file: " +
            parsedValues.length.toString();

          let dataIdentD = new Date();
          if (identData2 !== "") {
            const [day, month, year] = identData2.split("/");
            const dataIdent = new Date(+year, +month - 1, +day);
            dataIdentD = new Date(
              Date.UTC(
                dataIdent.getFullYear(),
                dataIdent.getMonth(),
                dataIdent.getDate(),
                1,
                0,
                0
              )
            );
          }

          const anagraficaResp = await apiAddAnagrafica.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            parentId: 0,
            isPg: false,
            cf: rappCf,
            nome: nome,
            cognome: cognome,
            nascitaLuogo: rappNascitaComune,
            nascitaData: dataNascitaD,
            professione: professione,
            denominazione: rappDenominazione,
            pIva: "",
            reaComune: "",
            reaN: "",
            addrPaeseId: paeseId,
            addrProvinciaId:
            rappAddrProvincia !== "EE" ? rappAddrProvincia : null,
            addrProvincia: rappAddrProvincia === "EE" ? rappAddrProvincia : "",
            addrComuneId: rappAddrProvincia !== "EE" ? rappAddrComune : null,
            addrComune: rappAddrProvincia === "EE" ? rappAddrComune : "",
            addrIndirizzo: rappAddrIndirizzo,
            addrN: "",
            addrCap: rappAddrCap,
            addr2PaeseId: null,
            addr2ProvinciaId: null,
            addr2Provincia: "",
            addr2ComuneId: null,
            addr2Comune: "",
            addr2Indirizzo: "",
            addr2N: "",
            addr2Cap: "",
            isPep: false,
            isNotPepMoreThanYear: false,
            versionN: 1,
            versionDate: dataIdentD,
            isDeleted: false,
            genereId: genereId,
            importDesc: importDesc,
            workspaceId: null,
            Office1Id: null,
            Office2Id: null,
          });

          if (anagraficaResp.data.errors !== undefined) {
          } else {
            anagLegRapp = anagraficaResp.data.data.insert_anagrafica_one;
            const result = anagraficaResp.data.data.insert_anagrafica_one;

            /**************************************/
            //Aggiungo l'identificazione
            /**************************************/
            let dataDocRilIlD = null;
            if (identDocRilData2 !== "") {
              const [day, month, year] = identDocRilData2.split("/");
              const dataDocRilIl = new Date(+year, +month - 1, +day);
              dataDocRilIlD = new Date(
                Date.UTC(
                  dataDocRilIl.getFullYear(),
                  dataDocRilIl.getMonth(),
                  dataDocRilIl.getDate(),
                  1,
                  0,
                  0
                )
              );
            }

            let dataDocScadD = null;
            if (docDataScadRapp !== "") {
              const [day, month, year] = docDataScadRapp.split("/");
              const dataDocScad = new Date(+year, +month - 1, +day);
              dataDocScadD = new Date(
                Date.UTC(
                  dataDocScad.getFullYear(),
                  dataDocScad.getMonth(),
                  dataDocScad.getDate(),
                  1,
                  0,
                  0
                )
              );
            } else {
              if (identDocRilData2 !== "") {
                const [day, month, year] = identDocRilData2.split("/");
                const dataDocScad = new Date(+year, +month - 1, +day);
                dataDocScadD = new Date(
                  Date.UTC(
                    dataDocScad.getFullYear(),
                    dataDocScad.getMonth(),
                    dataDocScad.getDate(),
                    1,
                    0,
                    0
                  )
                );
              }
            }

            if (
              dataIdentD !== null &&
              dataDocRilIlD !== null &&
              dataDocScadD !== null
            ) {
              const addAnagIdentResponse = await apiAddAnagraficaIdent.fetch({
                anagraficaId: result.Id,
                modalitaIdentId: identTipo2,
                docTipoId: identDocTipo2,
                docN: identDocN2,
                docRilDa: identDocRilAutorita2,
                docRilData: dataDocRilIlD,
                docScad: dataDocScadD,
                apNome: identCfProf2.includes("-") ? "" : identCfProf2,
                apCf: identCfProf2.includes("-") ? "" : identCfProf2,
                identifiedByUser: identCfProf2,
                identifiedOn: dataIdentD,
              });
            }

            /**************************************/
            //Aggiungo la relazione tra anagrafica e fascicolo
            /**************************************/
            if (!isInFascicoloLegRapp) {
              const addAnagFascicoloResponse =
                await apiAddAnagraficaFascicolo.fetch({
                  anagraficaId: result.Id,
                  fascicoloId: fascicolo.id,
                  tipoAnagId: tipoAnagId,
                });
            }

            /**************************************/
            //Salvo le modifiche su Workspace
            /**************************************/
            try {
              var anagByWs = await searchAnagOnWorkSpace(rappCf)
              let workspaceId = ""
              let officeId = ""
              let amlId = ""
              if(anagByWs !== undefined && anagByWs.length > 0)
              {
                //Recupero gli id dal workspace
                workspaceId = anagByWs[0].id
                officeId = getOfficesByWsId(workspaceId)
                amlId = getAmlByWsId(workspaceId)
              } else {
                //Posto i nuovi dati su workspace
                var response = await savePersonalDataOnWorkspace(rappCf,"",rappDenominazione,nome,cognome,rappNascitaData,rappNascitaComune,false,genereId)
                workspaceId = response !== undefined ? response.data.id : "";
                officeId = await saveOfficesOnWorkSpace(workspaceId,rappAddrComune,rappAddrProvincia,rappAddrIndirizzo,paeseId,rappAddrCap)
                amlId = await saveAmlDatasOnWorkSpace(workspaceId,identCfProf2,identDocTipo2,professione,false)
              }

              if(workspaceId !== "" && officeId !== "" && amlId !== ""){
                await saveIdsOnDatabase(workspaceId, officeId,amlId, result, false);
              }
            } catch (e) {
              apiAddLog.fetch({
                tenantId: sharedDatas.getPropertyByName("tenantId"),
                aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "AnagraficaImportDesktop",
                method: "createLegaleRapp",
                version: sharedDatas.getPropertyByName("version"),
              });
            }
          }
        }

        /**************************************/
        //Titolare Effettivo
        /**************************************/
        if (isLegaleRappTitEff) {
          const tipoAnagKey3 = "tipo_anagrafica_5";
          const tipoAnagByKeyResp3 = await apiTipoAnagraficaByKey.fetch({
            key: tipoAnagKey3,
          });
          const tipoAnagId3 =
            tipoAnagByKeyResp3.data.data.base_tipo_anagrafica.length > 0
              ? tipoAnagByKeyResp3.data.data.base_tipo_anagrafica[0].Id
              : 0;

          const tipoPartId = baseTipoPartList.filter(
            (x) => x.key === "tipo_partecipazione_10"
          )[0].id;

          //Se l'anagrafica non è collegata al fascicolo
          if (!isInFascicoloTitEff) {
            //Aggiungo la relazione tra anagrafica e fascicolo
            const addAnagFascicoloResponse =
              await apiAddAnagraficaFascicolo.fetch({
                anagraficaId: anagLegRapp.Id,
                fascicoloId: fascicolo.id,
                tipoAnagId: tipoAnagId3,
                tipoPartId: tipoPartId,
              });
          }         
        }

        return anagLegRapp;
      } else {
        return null;
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "createLegaleRapp",
        version: sharedDatas.getPropertyByName("version"),
      });
      return null;
    }
  };
  const createLegaleRappMulti = async (fascicolo, item) => {
    try {
      const rappAddrPaeseEstero = item[10];
      const rappCognome = item[19];
      const rappNome = item[20];
      const rappNascitaComune = item[21];
      const rappNascitaData = item[23];
      const rappGender = item[24] === "2" ? "F" : "M";
      const rappAddrIndirizzo = item[25];
      let rappAddrComune = item[26];
      let rappAddrProvincia = item[27];
      const rappCf = item[28].trimStart().trimEnd();

      const legame = "00"; //00 = Legale rappresentante (principale); 07 = Mandato con rappresentanza; 08 mandato senza rappresentanza; 09 = altro

      if (
        legame !== "" &&
        rappCognome !== "" &&
        rappNome !== "" &&
        rappNascitaComune !== "" &&
        rappNascitaData !== "" &&
        rappGender !== "" &&
        rappAddrIndirizzo !== "" &&
        rappAddrProvincia !== "" &&
        rappAddrComune !== ""
      ) {
        /**************************************/
        //Recupero i valori mappati
        /**************************************/
        if (rappAddrProvincia !== "EE") {
          if (
            rappAddrProvincia !== null &&
            rappAddrProvincia !== "" &&
            parsedArrProvinceList.filter((x) => x === rappAddrProvincia)
              .length > 0 &&
            mappedValuesList.filter(
              (x) =>
                x.type === "provincia" && x.mappedValue === rappAddrProvincia
            ).length > 0
          ) {
            rappAddrProvincia = parseInt(
              mappedValuesList.filter(
                (x) =>
                  x.type === "provincia" && x.mappedValue === rappAddrProvincia
              )[0].value
            );
          } else {
            rappAddrProvincia =
              rappAddrProvincia !== ""
                ? parseInt(
                    baseProvinceList.filter(
                      (x) =>
                        x.sigla.toLowerCase() ===
                        rappAddrProvincia.toLowerCase()
                    )[0].id
                  )
                : null;
          }

          if (
            rappAddrComune !== null &&
            rappAddrComune !== "" &&
            parsedArrComuniList.filter((x) => x === rappAddrComune).length >
              0 &&
            mappedValuesList.filter(
              (x) => x.type === "comune" && x.mappedValue === rappAddrComune
            ).length > 0
          ) {
            rappAddrComune = parseInt(
              mappedValuesList.filter(
                (x) => x.type === "comune" && x.mappedValue === rappAddrComune
              )[0].value
            );
          } else {
            rappAddrComune =
              rappAddrComune !== ""
                ? parseInt(
                    baseComuniList.filter(
                      (x) =>
                        x.nome.toLowerCase() === rappAddrComune.toLowerCase()
                    )[0].id
                  )
                : null;
          }
        }

        /**************************************/
        //Anagrafica
        /**************************************/
        const tipoAnagKey =
          legame === "00" ? "tipo_anagrafica_3" : "tipo_anagrafica_4";
        const tipoAnagByKeyResp = await apiTipoAnagraficaByKey.fetch({
          key: tipoAnagKey,
        });
        const tipoAnagId =
          tipoAnagByKeyResp.data.data.base_tipo_anagrafica.length > 0
            ? tipoAnagByKeyResp.data.data.base_tipo_anagrafica[0].Id
            : 0;

        const paeseByIdResp = await apiPaeseByIdent.fetch({
          identificativo: "ITA",
        });
        let paeseId =
          paeseByIdResp.data.data.base_paese.length > 0
            ? paeseByIdResp.data.data.base_paese[0].Id
            : 0;
        if (rappAddrProvincia === "EE") {
          const paeseResp = await apiBasePaeseByNome.fetch({
            nome: rappAddrPaeseEstero.toLowerCase(),
          });
          if (paeseResp.data.data.base_paese.length > 0) {
            paeseId = paeseResp.data.data.base_paese[0].Id;
          }
        }

        let genereId = 0;
        const baseGenereListResponse = await apiBaseGenere.fetch({
          tenantId: tenantId,
        });
        if (baseGenereListResponse.data.data.base_genere.length > 0) {
          genereId = baseGenereListResponse.data.data.base_genere.filter(
            (x) => x.Value === rappGender
          )[0].Id;
        }

        let dataNascitaD = null;
        if (rappNascitaData !== "") {
          const [day, month, year] = rappNascitaData.split("/");
          const dataNascita = new Date(+year, +month - 1, +day);
          dataNascitaD = new Date(
            Date.UTC(
              dataNascita.getFullYear(),
              dataNascita.getMonth(),
              dataNascita.getDate(),
              1,
              0,
              0
            )
          );
        }

        let anagLegRapp = null;
        const anagLegRappResponse = await apiAnagByCf.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          cf: "%" + rappCf + "%",
          nome: "%%",
          cognome: "%%",
        });

        let isInFascicoloLegRapp = false;
        let isInFascicoloTitEff = false;

        if (
          anagLegRappResponse !== null &&
          anagLegRappResponse.data.data.anagrafica.length > 0
        ) {
          anagLegRapp = anagLegRappResponse.data.data.anagrafica[0];

          isInFascicoloLegRapp =
            anagLegRapp.anagrafica_fascicolos.length > 0 &&
            anagLegRapp.anagrafica_fascicolos.filter(
              (y) =>
                (y.FascicoloId === fascicolo.id &&
                  y.base_tipo_anagrafica.Key === "tipo_anagrafica_3") ||
                (y.FascicoloId === fascicolo.id &&
                  y.base_tipo_anagrafica.Key === "tipo_anagrafica_4")
            ).length > 0;
          isInFascicoloTitEff =
            anagLegRapp.anagrafica_fascicolos.length > 0 &&
            anagLegRapp.anagrafica_fascicolos.filter(
              (y) =>
                y.FascicoloId === fascicolo.id &&
                y.base_tipo_anagrafica.Key === "tipo_anagrafica_5"
            ).length > 0;

          //Se l'anagrafica non è collegata al fascicolo
          if (!isInFascicoloLegRapp) {
            //Aggiungo la relazione tra anagrafica e fascicolo
            const addAnagFascicoloResponse =
              await apiAddAnagraficaFascicolo.fetch({
                anagraficaId: anagLegRapp.Id,
                fascicoloId: fascicolo.id,
                tipoAnagId: tipoAnagId,
              });
          }
        } else {
          //Dati import
          let importDesc = "";
          importDesc =
            "Data: " +
            new Date().toString() +
            " - File: " +
            parsedFile +
            " - N. records file: " +
            parsedValues.length.toString();

          const anagraficaResp = await apiAddAnagrafica.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            parentId: 0,
            isPg: false,
            cf: rappCf,
            nome: rappNome,
            cognome: rappCognome,
            nascitaLuogo: rappNascitaComune,
            nascitaData: dataNascitaD,
            professione: "",
            denominazione: "",
            pIva: "",
            reaComune: "",
            reaN: "",
            addrPaeseId: paeseId,
            addrProvinciaId:
            rappAddrProvincia !== "EE" ? rappAddrProvincia : null,
            addrProvincia: rappAddrProvincia === "EE" ? rappAddrProvincia : "",
            addrComuneId: rappAddrProvincia !== "EE" ? rappAddrComune : null,
            addrComune: rappAddrProvincia === "EE" ? rappAddrComune : "",
            addrIndirizzo: rappAddrIndirizzo,
            addrN: "",
            addrCap: "",
            addr2PaeseId: null,
            addr2ProvinciaId: null,
            addr2Provincia: "",
            addr2ComuneId: null,
            addr2Comune: "",
            addr2Indirizzo: "",
            addr2N: "",
            addr2Cap: "",
            isPep: false,
            isNotPepMoreThanYear: false,
            versionN: 1,
            versionDate: new Date(),
            isDeleted: false,
            genereId: genereId,
            importDesc: importDesc,
            workspaceId: null,
            Office1Id: null,
            Office2Id: null,
          });

          if (anagraficaResp.data.errors !== undefined) {
          } else {
            anagLegRapp = anagraficaResp.data.data.insert_anagrafica_one;
            const result = anagraficaResp.data.data.insert_anagrafica_one;

            /**************************************/
            //Aggiungo la relazione tra anagrafica e fascicolo
            /**************************************/
            if (!isInFascicoloLegRapp) {
              const addAnagFascicoloResponse =
                await apiAddAnagraficaFascicolo.fetch({
                  anagraficaId: result.Id,
                  fascicoloId: fascicolo.id,
                  tipoAnagId: tipoAnagId,
                });
            }

            /**************************************/
            //Salvo le modifiche su Workspace
            /**************************************/            
            try {
              var anagByWs = await searchAnagOnWorkSpace(rappCf)
              let workspaceId = ""
              let officeId = ""

              if(anagByWs !== undefined && anagByWs.length > 0)
              {
                //Recupero gli id dal workspace
                workspaceId = anagByWs[0].id
                officeId = await getOfficesByWsId(workspaceId)
              } else {
                //Posto i nuovi dati su workspace
                var response = await savePersonalDataOnWorkspace(rappCf,"","",rappNome,rappCognome,rappNascitaData,rappNascitaComune,false,genereId)
                workspaceId = response !== undefined ? response.data.id : "";
                officeId = await saveOfficesOnWorkSpace(workspaceId,rappAddrComune,rappAddrProvincia,rappAddrIndirizzo,paeseId,"")              
              }
              
              if(workspaceId !== "" && officeId !== ""){
                await saveIdsOnDatabase(workspaceId, officeId,"", result, false);
              }             
            } catch (e) {
              apiAddLog.fetch({
                tenantId: sharedDatas.getPropertyByName("tenantId"),
                aziendaId: sharedDatas.getPropertyByName("aziendaId"),
                level: "ERROR",
                message: e.message,
                stack: e.stack,
                area: "AnagraficaImportDesktop",
                method: "createLegaleRapp",
                version: sharedDatas.getPropertyByName("version"),
              });
            }
          }
        }

        return anagLegRapp;
      } else {
        return null;
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "createLegaleRapp",
        version: sharedDatas.getPropertyByName("version"),
      });
      return null;
    }
  };
  const createPrestazione = async (
    fascicolo,
    identCfProf,
    data,
    paeseId,
    addrProvincia,
    addrComune
  ) => {
    try {
      if (
        mappedValuesList.filter(
          (x) => x.type === "professionista" && x.value === identCfProf
        ).length > 0
      ) {
        const profCfArr = mappedValuesList.filter(
          (x) => x.type === "professionista" && x.value === identCfProf
        );
        let profCf = "";

        for (let i = 0; i < mappedProfPrestList.length; i++) {
          if (
            profCfArr.filter((x) => x.mappedValue === mappedProfPrestList[i].cf)
              .length > 0
          ) {
            profCf = profCfArr.filter(
              (x) => x.mappedValue === mappedProfPrestList[i].cf
            )[0].mappedValue;
          }
        }

        if (
          profCf !== "" &&
          mappedProfPrestList.filter((x) => x.cf === profCf).length > 0
        ) {
          const mappedProfPrestItem = mappedProfPrestList.filter(
            (x) => x.cf === profCf
          )[0];

          const addPresResponse = await apiAddPrestazione.fetch({
            fascicoloId: fascicolo.id,
            tipoKey: "N",
            prestazioneId: mappedProfPrestItem.prestId,
            naturaId: baseNaturaPrestazioneList.filter(
              (x) => x.key === "base_natura_prestazione_3"
            )[0].id,
            scopoId: baseScopoPrestazioneList.filter(
              (x) => x.key === "base_scopo_prestazione_1"
            )[0].id,
            startDate: data !== null ? data : new Date(),
            endDate: null,
            paeseId: paeseId,
            provinciaId: addrProvincia !== "EE" ? addrProvincia : null,
            provincia: addrProvincia === "EE" ? addrProvincia : "",
            cittaId: addrProvincia !== "EE" ? addrComune : null,
            citta: addrProvincia === "EE" ? addrComune : "",
            descrizione: "",
            isPrivate: false,
            astensioneMotivo: "",
            isEsoneroObbAst: false,
          });

          if (addPresResponse.data.errors !== undefined) {
          } else {
            const result = addPresResponse.data.data.insert_prestazione_one;

            //Aggiungo i professionisti appartenenti alla prestazione
            let professionisti = [];
            professionisti.push({
              PrestazioneId: result.Id,
              UserId: identCfProf,
            });

            const addPresUsersResponse = await apiAddPrestazioneUsers.fetch({
              objects: professionisti,
            });
          }
        }
      }
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "createPrestazione",
        version: sharedDatas.getPropertyByName("version"),
      });
      return null;
    }
  };
  const searchAnagOnWorkSpace = async (cf) => {
    try {
      var request = {
        ownerId : tenantId,
        identifier : cf,
        status : "ACTIVE"
      }
      const resp = await tsApiWsRead.fetch({
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",     
          "X-Item-ID": tenantId,     
          "X-User-ID": loggedUserId,
        },
        data : request        
      })

      return resp.data.content      
    } catch (e) {
      
    }
  };
  const savePersonalDataOnWorkspace = async (
    cf,
    pIva,
    denominazione,
    nome,
    cognome,
    birthDate,
    birthPlace,
    isPg,
    genderId
  ) => {    
    try {
      var data = null;
      if (isPg) {
        data = {
          ownerId: tenantId,
          //itemId: "",
          classifier: "COMPANY",
          personalData: {
            taxIdentifier: cf,
            vatIdentifier: pIva,
            businessName: denominazione,
            countryCode: "IT",            
          },
          //validityStart: new Date(),
          baseCurrency: "EUR",
        };
      } else {
        data = {
          ownerId: tenantId,          
          classifier: "PERSON",
          personalData: {
            taxIdentifier: cf,
            countryCode: "IT",
            name: nome,
            surname: cognome,
            birthDate : birthDate !== null && birthDate !== "" ? new Date(birthDate.split("/")[2],birthDate.split("/")[1],birthDate.split("/")[0]) : "1900-01-01T01:00:00",
            birthplace : birthPlace,
            gender : genderId === 1 ? "M" : "F"
          },
          //validityStart: new Date(),
          baseCurrency: "EUR",
        };
      }
      
      var resp = await tsApiWorkspace.fetch({
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
        },
        data,
      });
      return resp;
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "savePersonalDataOnWorkspace",
        version: sharedDatas.getPropertyByName("version"),
      });
    }
  };
  const saveOfficesOnWorkSpace = async (
    workspaceId,
    addrComune,
    addrProvincia,
    addrIndirizzo,
    addrNazione,
    addrCap
  ) => {
    //Compongo i dati da postare su workspace
    
    var isItalianAddress = addrNazione === 89
    var address = addrIndirizzo.split(",")[0];
    var civicNumber = addrIndirizzo.split(",")[1];
    var officeData = {
      description: "Indirizzo Principale",
      officeType: "MAIN",
      streetNumber: civicNumber,
      streetName: address,
      province: isItalianAddress ? getProvincia(addrProvincia) : addrProvincia,
      city: isItalianAddress ? getComune(addrComune) : addrComune,
      district: "",
      zipCode: addrCap,
      region: "",
      country: getNazione(addrNazione),
      status: "ACTIVE",
      officeClassifier: [],
      registeredResidenceType: "REGISTERED_RESIDENCE",
      spaceType: "",
      streetDetails: "",
    };
    //Posto i dati su workspace
    try {
      var resp = await tsApiWorkspace.fetch({
        url : `aml:workspace-write:api://${workspaceId}/office`,
        method : 'POST',
        headers: {
            "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
            "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : workspaceId 
        },
        data : officeData
      })                            
      var officeId = resp.data !== null ? resp.data.id : ""
      return officeId;
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "saveOfficesOnWorkSpace",
        version: sharedDatas.getPropertyByName("version"),
      });
    }
  };
  const saveAmlDatasOnWorkSpace = async (workspaceId,identUser,docTipoId,prof,isPep)  => { 
    const toPostData = {
        verifiedBy : getIdentUser(identUser),
        verifiedWith : getDocumentTypeById(docTipoId),
        occupationalActivity : prof,
        politicallyExposedPerson : isPep,
    }
    try {
        var resp = await tsApiWorkspace.fetch({
            url : `aml:workspace-write:api://${workspaceId}/antiMoneyLaundering`,   
            method : 'POST',                                                      
            headers: {
                "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
                "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : workspaceId 
            },
            data : toPostData
        })         
        let amlId = resp.data !== null ? resp.data.id : "";
        return amlId;                                   
    } catch (e) {
        apiAddLog.fetch({ tenantId: sharedDatas.getPropertyByName("tenantId"), aziendaId: sharedDatas.getPropertyByName("aziendaId"), level: "ERROR", message: e.message, stack: e.stack, area: "AngraficaForm", method: "saveAmlDatasOnWorkSpace", version: sharedDatas.getPropertyByName("version") });
    }
  }
  const saveIdsOnDatabase = async (workspaceId, officeId,amlId, anagrafica, isPg) => {
    try {
      var response = await apiUpdateAnagrafica.fetch({
        id : anagrafica.Id,
        aziendaId: aziendaId,
        parentId : anagrafica.ParentId,
        isPg : anagrafica.IsPg,            
        cf : anagrafica.Cf,
        pIva : anagrafica.PIva,
        nome : anagrafica.Nome,
        cognome : anagrafica.Cognome,
        denominazione: anagrafica.Denominazione,
        nascitaData : anagrafica.NascitaData,
        nascitaLuogo : anagrafica.NascitaLuogo,
        professione: anagrafica.Professione,
        genereId : anagrafica.GenereId,
        reaComune: "", 
        reaN: "",
        tenantId: tenantId,
        workspaceId : workspaceId,
        Office1Id : officeId,
        AmlId : amlId,
        addrComuneId: anagrafica.AddrComuneId,
        addrProvinciaId: anagrafica.AddrProvinciaId,
        addrProvincia: anagrafica.AddrProvincia === "EE" ? anagrafica.AddrProvincia : "",   
        addrComune: anagrafica.AddrComune === "EE" ? anagrafica.AddrComune : "",
        addrPaeseId : anagrafica.AddrPaeseId,
        addrIndirizzo : anagrafica.AddrIndirizzo,
        addrCap : anagrafica.AddrCap,
        isPep : anagrafica.IsPep,
        isNotPepMoreThanYear: anagrafica.IsNotPepMoreThanYear,
        versionN : anagrafica.VersionN,
        isDeleted : false,
        versionDate : anagrafica.VersionDate    
      });
    } catch (e) {
      apiAddLog.fetch({
        tenantId: sharedDatas.getPropertyByName("tenantId"),
        aziendaId: sharedDatas.getPropertyByName("aziendaId"),
        level: "ERROR",
        message: e.message,
        stack: e.stack,
        area: "AnagraficaImportDesktop",
        method: "saveIdsOnDatabase",
        version: sharedDatas.getPropertyByName("version"),
      });
    }
  };
  const getOfficesByWsId = async (workspaceId)  => {
    try {
      var resp = await tsApiWsReadGet.fetch({
        url : `workspaceReadApi:///${workspaceId}/office/`,
        headers: {
          "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : workspaceId 
      },
      })
      var officeId = resp.data !== null ? resp.data[0].id : ""
      return officeId;
    } catch (e) { 
      
    }
  }
  const getAmlByWsId = async (workspaceId) => {
    try {
      var resp = await tsApiWsReadGet.fetch({
        url : `workspaceReadApi:///${workspaceId}/antiMoneyLaundering/`,
        headers: {
          "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : workspaceId 
      },
      })
      var amlId = resp.data !== null ? resp.data.id : ""
      return amlId;
    } catch (e) {
      
    }
  }
  //Tabs
  const [selectedTab, setSelectedTab] = useState("tabImportabili");
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

   const getProvincia = (provinciaId) => {            
    let selProv = baseProvinceList.filter(prov => prov.id  === provinciaId)[0]
    return selProv!== "" && selProv.nome !== "" ? selProv.nome : ""
  }
  const getComune = (comuneId) => {      
    let selComune = baseComuniList.filter(comune => comune.id === comuneId)[0]    
    return selComune!== "" && selComune.nome !== "" ? selComune.nome : ""
  }
  const getNazione = (nazioneId) => {      
    let selNazione = basePaeseList.filter(naz=> naz.id === nazioneId)[0]
    return selNazione!== "" && selNazione.nome !== "" ? selNazione.nome : ""
  }

  const getDocumentTypeById = (tipoDoc) => {   
    switch (tipoDoc) {
      case 1:
        return "IDENTITY_CARD";
      case 2:
        return "PASSPORT";     
      case 4:
        return "RESIDENCY_PERMIT";
      case 5:
        return "DRIVERS_LICENSE";
      case 6:
        return "FIREARM_LICENSE";
      default:
        return "OTHER";
    }
  };

  const getIdentUser = (identUserId) => {    
    let selIdentUser = utentiList.filter(user=> user.userId === identUserId)[0]
    return selIdentUser.completeName
  }
  return (
    <Fragment>
      <VaporPage
        title={
          selImportType === "aricdesktop"
            ? "Import da Antiriciclaggio Desktop"
            : "Import da TS Studio Contabilità"
        }
      >
        <Loading open={backDropOpen} />

        {isDataLoaded && !userPermissions.canCreate ? <NotAuth /> : null}

        {isDataLoaded && userPermissions.canCreate ? (
          <VaporPage.Section>
            {!importFinished ? (
              <Fragment>
                <Stack direction="row" sx={{ mb: 3 }}>
                  <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                    <ExtendedTypography
                      variant="titleSmall"
                      color="primary.main"
                    >
                      {t("anagrafica.ImportDesktop.uploadFile")}
                    </ExtendedTypography>

                    {selImportType === "aricdesktop" ? (
                      <VaporUploader
                        buttonVariant="contained"
                        draggable
                        multiple
                        inputFileProps={{ accept: "text/plain, text/csv" }}
                        label=""
                        draggableText="Trascina qui il file prodotto ed esportato dalla versione desktop"
                        dropText="Rilascia qui il file prodotto ed esportato dalla versione desktop"
                        buttonLabel="Seleziona il file"
                        onFileUpload={handleUploadeFile}
                      />
                    ) : null}

                    {selImportType === "multi" ? (
                      <VaporUploader
                        buttonVariant="contained"
                        draggable
                        multiple
                        inputFileProps={{ accept: "text/plain, text/csv" }}
                        label=""
                        draggableText="Trascina qui il file prodotto ed esportato da TS Studio Contabilità"
                        dropText="Rilascia qui il file prodotto ed esportato da TS Studio Contabilità"
                        buttonLabel="Seleziona il file"
                        onFileUpload={handleUploadeFileMulti}
                      />
                    ) : null}
                  </Stack>
                </Stack>

                {dataRows.length > 0 || dataRows2.length > 0 ? (
                  <Fragment>
                    <Divider variant="standard" />
                    <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ width: "70%" }}
                      >
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("anagrafica.ImportDesktop.selFiles")}
                        </ExtendedTypography>

                        <ExtendedTabs
                          variant="standard"
                          size="small"
                          value={selectedTab}
                          onChange={handleChangeTab}
                          sx={{ mb: 2 }}
                        >
                          <ExtendedTab
                            value="tabImportabili"
                            label="Disponibili"
                          />
                          <ExtendedTab
                            value="tabNonDisponibili"
                            label="Fascicoli già presenti"
                          />
                        </ExtendedTabs>

                        {selectedTab === "tabImportabili" ? (
                          <Stack
                            direction="column"
                            spacing={0}
                            justifyContent="left"
                            alignItems="left"
                          >
                            {dataItems.length > 0 &&
                            dataColumns.length > 0 &&
                            dataRows.length > 0 ? (
                              <DataGridPro
                                rows={dataRows}
                                columns={dataColumns}
                                slots={{ toolbar: GridToolbar }}
                                autoHeight={true}
                                pageSizeOptions={[25, 50, 100]}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                pagination
                                disableSelectionOnClick
                                checkboxSelection
                                rowSelectionModel={selectedRows}
                                isRowSelectable={(params) =>
                                  params.row.isComplete
                                }
                                onRowSelectionModelChange={(id) => {
                                  handleSelectedRow(id);
                                }}
                                initialState={{
                                  columns: {
                                    columnVisibilityModel: {
                                      id: false,
                                    },
                                  },
                                  pagination: {
                                    paginationModel: {
                                      pageSize: 25,
                                    },
                                  },
                                }}
                              />
                            ) : (
                              <NoData />
                            )}
                          </Stack>
                        ) : null}

                        {selectedTab === "tabNonDisponibili" ? (
                          <Stack
                            direction="column"
                            spacing={0}
                            justifyContent="left"
                            alignItems="left"
                          >
                            {dataRows2.length > 0 ? (
                              <DataGridPro
                                rows={dataRows2}
                                columns={dataColumns2}
                                slots={{ toolbar: GridToolbar }}
                                autoHeight={true}
                                pageSizeOptions={[25, 50, 100]}
                                localeText={
                                  itIT.components.MuiDataGrid.defaultProps
                                    .localeText
                                }
                                pagination
                                disableRowSelectionOnClick
                                getRowId={(row) => row.fascicoloId}
                                initialState={{
                                  columns: {
                                    columnVisibilityModel: {
                                      fascicoloId: false,
                                    },
                                  },
                                  pagination: {
                                    paginationModel: {
                                      pageSize: 25,
                                    },
                                  },
                                }}
                              />
                            ) : (
                              <NoData />
                            )}
                          </Stack>
                        ) : null}
                      </Stack>
                      <Stack
                        direction="column"
                        spacing={1}
                        sx={{ width: "30%" }}
                      >
                        <ExtendedTypography
                          variant="titleSmall"
                          color="primary.main"
                        >
                          {t("anagrafica.ImportDesktop.assInfo")}
                        </ExtendedTypography>

                        {selImportType === "aricdesktop" && parsedArrProfList.length > 0 ? (
                          <Fragment>
                            <Divider variant="standard" />
                            <ExtendedTypography
                              variant="body"
                              color="primary.main"
                            >
                              {t("anagrafica.ImportDesktop.prof")}
                            </ExtendedTypography>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="35%">Valore</TableCell>
                                    <TableCell width="65%" align="right">
                                      {t("anagrafica.ImportDesktop.assCon")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedArrProfList.map((item) => (
                                    <Fragment key={uuidv4()}>
                                      <TableRow
                                        key={uuidv4()}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        hover
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ pl: 1 }}
                                          width="35%"
                                        >
                                          {item}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          width="65%"
                                          sx={{ pr: 1 }}
                                        >
                                          <Autocomplete
                                            id="ddlProf"
                                            size="small"
                                            fullWidth
                                            options={utentiList}
                                            sx={{ width: "100%" }}
                                            onChange={(e, v) =>
                                              handleChange(e, v, item)
                                            }
                                            isOptionEqualToValue={(
                                              option,
                                              value
                                            ) =>
                                              mappedValuesList.filter(
                                                (x) =>
                                                  x.type === "professionista" &&
                                                  x.mappedValue === item
                                              ).length === 0
                                                ? option.userId === value.userId
                                                : option.userId ===
                                                  mappedValuesList.filter(
                                                    (x) =>
                                                      x.type ===
                                                        "professionista" &&
                                                      x.mappedValue === item
                                                  )[0].value
                                            }
                                            getOptionLabel={(option) =>
                                              option.completeName
                                            }
                                            defaultValue={
                                              mappedValuesList.filter(
                                                (x) =>
                                                  x.type === "professionista" &&
                                                  x.mappedValue === item
                                              ).length > 0
                                                ? utentiList.filter(
                                                    (x) =>
                                                      x.userId ===
                                                      mappedValuesList.filter(
                                                        (y) =>
                                                          y.type ===
                                                            "professionista" &&
                                                          y.mappedValue === item
                                                      )[0].value
                                                  )[0]
                                                : null
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                required
                                                label=""
                                                sx={{ lineHeight: 1 }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow
                                        key={uuidv4()}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        hover
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ pl: 1 }}
                                          width="35%"
                                        >
                                          {t("anagrafica.ImportDesktop.assPrest")}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          width="65%"
                                          sx={{ pr: 1 }}
                                        >
                                          <Autocomplete
                                            id="ddlPrest"
                                            size="small"
                                            fullWidth
                                            options={baseTipoPrestazioneList}
                                            sx={{ width: "100%" }}
                                            onChange={(e, v) =>
                                              handleChange(e, v, item)
                                            }
                                            getOptionLabel={(option) =>
                                              option.nome
                                            }
                                            defaultValue={
                                              mappedProfPrestList.filter(
                                                (x) => x.cf === item
                                              ).length > 0
                                                ? baseTipoPrestazioneList.filter(
                                                    (x) =>
                                                      x.id ===
                                                      mappedProfPrestList.filter(
                                                        (y) => y.cf === item
                                                      )[0].prestId
                                                  )[0]
                                                : null
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                required
                                                label=""
                                                sx={{ lineHeight: 1 }}
                                              />
                                            )}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </Fragment>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}    
                        {selImportType === "multi" && parsedArrProfList.length > 0 ? (
                          <Fragment>
                          <Divider variant="standard" />
                          <ExtendedTypography
                            variant="body"
                            color="primary.main"
                          >
                            {t("anagrafica.ImportDesktop.prof")}
                          </ExtendedTypography>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell width="35%">Valore</TableCell>
                                  <TableCell width="65%" align="right">
                                    {t("anagrafica.ImportDesktop.assCon")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {parsedArrProfList.map((item) => (
                                  <Fragment key={uuidv4()}>
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      hover
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ pl: 1 }}
                                        width="35%"
                                      >
                                        {item}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        width="65%"
                                        sx={{ pr: 1 }}
                                      >
                                        <Autocomplete
                                          id="ddlProf"
                                          size="small"
                                          fullWidth
                                          options={utentiList}
                                          sx={{ width: "100%" }}
                                          onChange={(e, v) =>
                                            handleChange(e, v, item)
                                          }
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) =>
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "professionista" &&
                                                x.mappedValue === item
                                            ).length === 0
                                              ? option.userId === value.userId
                                              : option.userId ===
                                                mappedValuesList.filter(
                                                  (x) =>
                                                    x.type ===
                                                      "professionista" &&
                                                    x.mappedValue === item
                                                )[0].value
                                          }
                                          getOptionLabel={(option) =>
                                            option.completeName
                                          }
                                          defaultValue={
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "professionista" &&
                                                x.mappedValue === item
                                            ).length > 0
                                              ? utentiList.filter(
                                                  (x) =>
                                                    x.userId ===
                                                    mappedValuesList.filter(
                                                      (y) =>
                                                        y.type ===
                                                          "professionista" &&
                                                        y.mappedValue === item
                                                    )[0].value
                                                )[0]
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              required
                                              label=""
                                              sx={{ lineHeight: 1 }}
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>                                    
                                  </Fragment>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Fragment>
                        ) : null}                    
                        {parsedArrProvinceList.length > 0 ? (
                          <Fragment>
                            <Divider variant="standard" />
                            <ExtendedTypography
                              variant="body"
                              color="primary.main"
                            >
                              {t("anagrafica.ImportDesktop.prov")}
                            </ExtendedTypography>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="35%">Valore</TableCell>
                                    <TableCell width="65%" align="right">
                                      {t("anagrafica.ImportDesktop.assCon")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedArrProvinceList.map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      hover
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ pl: 1 }}
                                        width="35%"
                                      >
                                        {item}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        width="65%"
                                        sx={{ pr: 1 }}
                                      >
                                        <Autocomplete
                                          id="ddlProvince"
                                          size="small"
                                          fullWidth
                                          options={baseProvinceList}
                                          sx={{ width: "100%" }}
                                          onChange={(e, v) =>
                                            handleChange(e, v, item)
                                          }
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) =>
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "provincia" &&
                                                x.mappedValue === item
                                            ).length === 0
                                              ? option.id === value.id
                                              : option.id ===
                                                parseInt(
                                                  mappedValuesList.filter(
                                                    (x) =>
                                                      x.type === "provincia" &&
                                                      x.mappedValue === item
                                                  )[0].value
                                                )
                                          }
                                          getOptionLabel={(option) =>
                                            option.nome
                                          }
                                          defaultValue={
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "provincia" &&
                                                x.mappedValue === item
                                            ).length > 0
                                              ? baseProvinceList.filter(
                                                  (x) =>
                                                    x.id ===
                                                    parseInt(
                                                      mappedValuesList.filter(
                                                        (y) =>
                                                          y.type ===
                                                            "provincia" &&
                                                          y.mappedValue === item
                                                      )[0].value
                                                    )
                                                )[0]
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              required
                                              label=""
                                              sx={{ lineHeight: 1 }}
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}

                        {parsedArrComuniList.length > 0 ? (
                          <Fragment>
                            <Divider variant="standard" />
                            <ExtendedTypography
                              variant="body"
                              color="primary.main"
                            >
                              {t("anagrafica.ImportDesktop.comuni")}
                            </ExtendedTypography>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="35%">Valore</TableCell>
                                    <TableCell align="right" width="65%">
                                      {t("anagrafica.ImportDesktop.assCon")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedArrComuniList.map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      hover
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ pl: 1 }}
                                        width="35%"
                                      >
                                        {item}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        width="65%"
                                        sx={{ pr: 1 }}
                                      >
                                        <Autocomplete
                                          id="ddlComuni"
                                          size="small"
                                          fullWidth
                                          options={baseComuniList}
                                          sx={{ width: "100%" }}
                                          onChange={(e, v) =>
                                            handleChange(e, v, item)
                                          }
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) =>
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "comune" &&
                                                x.mappedValue === item
                                            ).length === 0
                                              ? option.id === value.id
                                              : option.id ===
                                                parseInt(
                                                  mappedValuesList.filter(
                                                    (x) =>
                                                      x.type === "comune" &&
                                                      x.mappedValue === item
                                                  )[0].value
                                                )
                                          }
                                          getOptionLabel={(option) =>
                                            option.nome +
                                            " (" +
                                            option.provincia +
                                            ")"
                                          }
                                          defaultValue={
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "comune" &&
                                                x.mappedValue === item
                                            ).length > 0
                                              ? baseComuniList.filter(
                                                  (x) =>
                                                    x.id ===
                                                    parseInt(
                                                      mappedValuesList.filter(
                                                        (y) =>
                                                          y.type === "comune" &&
                                                          y.mappedValue === item
                                                      )[0].value
                                                    )
                                                )[0]
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              required
                                              label=""
                                              sx={{ lineHeight: 1 }}
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}

                        {parsedArrTipoIdentList.length > 0 ? (
                          <Fragment>
                            <Divider variant="standard" />
                            <ExtendedTypography
                              variant="body"
                              color="primary.main"
                            >
                              {t("anagrafica.ImportDesktop.modIdent")}
                            </ExtendedTypography>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="35%">Valore</TableCell>
                                    <TableCell align="right" width="65%">
                                      {t("anagrafica.ImportDesktop.assCon")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedArrTipoIdentList.map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      hover
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ pl: 1 }}
                                        width="35%"
                                      >
                                        {item}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        width="65%"
                                        sx={{ pr: 1 }}
                                      >
                                        <Autocomplete
                                          id="ddlTipoIdent"
                                          size="small"
                                          fullWidth
                                          options={baseTipoIdentList}
                                          sx={{ width: "100%" }}
                                          onChange={(e, v) =>
                                            handleChange(e, v, item)
                                          }
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) =>
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "tipoIdent" &&
                                                x.mappedValue === item
                                            ).length === 0
                                              ? option.id === value.id
                                              : option.id ===
                                                parseInt(
                                                  mappedValuesList.filter(
                                                    (x) =>
                                                      x.type === "tipoIdent" &&
                                                      x.mappedValue === item
                                                  )[0].value
                                                )
                                          }
                                          getOptionLabel={(option) =>
                                            option.value
                                          }
                                          defaultValue={
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "tipoIdent" &&
                                                x.mappedValue === item
                                            ).length > 0
                                              ? baseTipoIdentList.filter(
                                                  (x) =>
                                                    x.id ===
                                                    parseInt(
                                                      mappedValuesList.filter(
                                                        (y) =>
                                                          y.type ===
                                                            "tipoIdent" &&
                                                          y.mappedValue === item
                                                      )[0].value
                                                    )
                                                )[0]
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              required
                                              label=""
                                              sx={{ lineHeight: 1 }}
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}

                        {parsedArrTipoDocList.length > 0 ? (
                          <Fragment>
                            <Divider variant="standard" />
                            <ExtendedTypography
                              variant="body"
                              color="primary.main"
                            >
                              {t("anagrafica.ImportDesktop.tipoDoc")}
                            </ExtendedTypography>
                            <TableContainer component={Paper}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="35%">Valore</TableCell>
                                    <TableCell align="right" width="65%">
                                      {t("anagrafica.ImportDesktop.assCon")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {parsedArrTipoDocList.map((item) => (
                                    <TableRow
                                      key={uuidv4()}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                      hover
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ pl: 1 }}
                                        width="35%"
                                      >
                                        {item}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        width="65%"
                                        sx={{ pr: 1 }}
                                      >
                                        <Autocomplete
                                          id="ddlModIdentDoc"
                                          size="small"
                                          fullWidth
                                          options={baseTipoDocList}
                                          sx={{ width: "100%" }}
                                          onChange={(e, v) =>
                                            handleChange(e, v, item)
                                          }
                                          isOptionEqualToValue={(
                                            option,
                                            value
                                          ) =>
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "tipoDoc" &&
                                                x.mappedValue === item
                                            ).length === 0
                                              ? option.id === value.id
                                              : option.id ===
                                                parseInt(
                                                  mappedValuesList.filter(
                                                    (x) =>
                                                      x.type === "tipoDoc" &&
                                                      x.mappedValue === item
                                                  )[0].value
                                                )
                                          }
                                          getOptionLabel={(option) =>
                                            option.value
                                          }
                                          defaultValue={
                                            mappedValuesList.filter(
                                              (x) =>
                                                x.type === "tipoDoc" &&
                                                x.mappedValue === item
                                            ).length > 0
                                              ? baseTipoDocList.filter(
                                                  (x) =>
                                                    x.id ===
                                                    parseInt(
                                                      mappedValuesList.filter(
                                                        (y) =>
                                                          y.type ===
                                                            "tipoDoc" &&
                                                          y.mappedValue === item
                                                      )[0].value
                                                    )
                                                )[0]
                                              : null
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              required
                                              label=""
                                              sx={{ lineHeight: 1 }}
                                            />
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Fragment>
                        ) : null}
                      </Stack>
                    </Stack>
                  </Fragment>
                ) : null}
              </Fragment>
            ) : (
              <Fragment>
                <Stack direction="row" sx={{ mb: 3 }}>
                  <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                    <ExtendedTypography
                      variant="titleSmall"
                      color="primary.main"
                    >
                      {t("anagrafica.ImportDesktop.esito")}
                    </ExtendedTypography>
                    <Divider variant="standard" />
                    <ExtendedTypography
                      variant="titleSmall"
                      color="primary.main"
                    >
                      {importFinishedCountOk} {t("anagrafica.ImportDesktop.impFasc")}
                    </ExtendedTypography>
                    <Divider variant="standard" />
                    <ExtendedTypography
                      variant="titleSmall"
                      color="primary.main"
                    >
                      {importFinishedErrors.length} {t("anagrafica.ImportDesktop.nonImpFasc")}
                    </ExtendedTypography>

                    {importFinishedErrors.length > 0 ? (
                      <Fragment>
                        <TableContainer component={Paper}>
                          <Table size="medium">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  {t("anagrafica.ImportDesktop.codFisc")}
                                </TableCell>
                                <TableCell align="left">{t("dashboard.piva")}</TableCell>
                                <TableCell align="left">
                                  {t("anagrafica.ImportDesktop.denom")}
                                </TableCell>
                                <TableCell align="left">{t("anagrafica.ImportDesktop.motivo")}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {importFinishedErrors.map((item) => (
                                <TableRow
                                  key={uuidv4()}
                                  hover
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ pl: 1 }}
                                    align="left"
                                  >
                                    {item.cf}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ pl: 1 }}
                                    align="left"
                                  >
                                    {item.pIva}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ pl: 1 }}
                                    align="left"
                                  >
                                    {item.denominazione}
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ pl: 1 }}
                                    align="left"
                                  >
                                    {item.reason}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Fragment>
                    ) : null}
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleNewImportClick}
                  >
                    {t("anagrafica.ImportDesktop.newImport")}
                  </Button>
                </Stack>
              </Fragment>
            )}
          </VaporPage.Section>
        ) : null}
      </VaporPage>

      <VaporToolbar
        variant="regular"
        size="medium"
        withoutAppBar={false}
        contentRight={
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              endIcon={<CancelIcon />}
              onClick={handleCancelClick}
              disabled={selectedRows.length <= 0}
            >
              {t("actions.annulla")}
            </Button>
            <Button
              variant="contained"
              size="medium"
              endIcon={<CheckIcon />}
              onClick={handleConfirmImportClick}
              disabled={selectedRows.length <= 0}
            >
              {t("anagrafica.ImportDesktop.confAndImp")}
            </Button>
          </Stack>
        }
      ></VaporToolbar>
    </Fragment>
  );
};
